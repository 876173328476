export const ENDPOINT = {
  verify_redeem_otp: "v1/auth/verify-otp",
  send_otp: "v1/auth/send-otp",
  list_balance_requests_page_limit: "v1/auth/list-balance-requests",
  edit_advertisement: "v1/auth/edit-advertisement",
  get_advertisement: "v1/auth/get-advertisement",
  approve_balance_requests_id: "v1/auth/approve-balance-requests",
  update_max_instant_balance_id: "v1/auth/update-max-instant-balance",
  add_card: "v1/auth/add-card",
  change_card_feature_status: "v1/auth/change-card-feature-status",
  change_card_status: "v1/auth/change-card-status",
  delete_card_id_skin_limit: "v1/auth/delete-card",
  delete_card_inventory_searchtext: "v1/auth/delete-card-inventory/searchtext",
  edit_card: "v1/auth/edit-card",
  fetch_api_categories_detail: "v1/auth/fetch-api-categories-detail",
  fetch_api_cards_id: "v1/auth/fetch-api-cards",
  list_card_skip_limit: "v1/auth/list-card",
  list_card_redeem_using_cardid: "v1/auth/list-card-redeem-using-cardid",
  list_company_card: "v1/auth/list-company-card",
  filter_card: "v1/auth/filter-card",
  filter_company_card: "v1/auth/filter-company-card",
  upload_single_card_redeem: "v1/auth/upload-single-card-redeem",
  add_company: "v1/auth/add-company",
  change_company_password: "v1/auth/change-company-password",
  change_company_status: "v1/auth/change-company-status",
  delete_company: "v1/auth/delete-company",
  edit_company: "v1/auth/edit-company",
  edit_company_form_question: "v1/auth/edit-company-form-question",
  list_companies: "v1/auth/list-companies",
  filter_company: "v1/auth/filter-company",
  list_company_pay_trn: "v1/auth/list-company-pay-trn",
  list_company_trn: "v1/auth/list-company-trn",
  search_all_company_trn_list: "v1/auth/search-all-company-trn-list",
  search_company_trn_list: "v1/auth/search-company-trn-list",
  all_kpis: "v1/auth/all-kpis",
  add_balance_distributor: "v1/auth/add-balance-distributor",
  create_distributor: "v1/auth/create-distributor",
  add_balance_user: "v1/auth/add-balance-user",
  assign_user: "v1/auth/assign-user",
  edit_distributor: "v1/auth/edit-distributor",
  list_distributor: "v1/auth/list-distributor",
  distributor_transactions: "v1/auth/distributor-transactions",
  list_assigned_user: "v1/auth/list-assigned-user",
  distributor: "v1/auth/distributor",
  create_admin: "v1/auth/create-admin",
  change_employee_status: "v1/auth/change-employee-status",
  edit_employee: "v1/auth/edit-employee",
  list_employee: "v1/auth/list-employee",
  filter_employee: "v1/auth/filter-employee",
  add_category: "v1/auth/add-category",
  change_category_status: "v1/auth/change-category-status",
  delete_category: "v1/auth/delete-category",
  edit_category: "v1/auth/edit-category",
  list_category: "v1/auth/list-category",
  filter_category: "v1/auth/filter-category",
  add_city: "v1/auth/add-city",
  delete_city: "v1/auth/delete-city",
  list_city: "v1/auth/list-city",
  edit_city: "v1/auth/edit-city",
  add_coin: "v1/auth/add-coin",
  change_country_gcc_status: "v1/auth/change-country-gcc-status",
  change_coin_status: "v1/auth/change-coin-status",
  delete_coin: "v1/auth/delete-coin",
  edit_coin: "v1/auth/edit-coin",
  list_coin: "v1/auth/list-coin",
  filter_coin: "v1/auth/filter-coin",
  add_country: "v1/auth/add-country",
  change_country_status: "v1/auth/change-country-status",
  delete_country: "v1/auth/delete-country",
  edit_country: "v1/auth/edit-country",
  list_country: "v1/auth/list-country",
  filter_country: "v1/auth/filter-country",
  create_currency: "v1/auth/create-currency",
  change_currency_status: "v1/auth/change-currency-status",
  delete_currency: "v1/auth/delete-currency",
  edit_currency: "v1/auth/edit-currency",
  add_type: "v1/auth/add-type",
  change_type_status: "v1/auth/change-type-status",
  delete_type: "v1/auth/delete-type",
  edit_type: "v1/auth/edit-type",
  list_type: "v1/auth/list-type",
  filter_type: "v1/auth/filter-type",
  list_balance_request_report: "v1/auth/list-balance-request-report",
  list_buy_card_report: "v1/auth/list-buy-card-report",
  sales_user_outstanding_report: "v1/auth/sales/user-outstanding-report",
  list_buy_max_card_sort: "v1/auth/list-buy-max-card-sort",
  list_card_report: "v1/auth/list-card-report",
  list_money_paid_to_company: "v1/auth/list-money-paid-to-company",
  list_user_balance_report: "v1/auth/list-user-balance-report",
  admin_profit_report: "v1/auth/admin-profit-report",
  sales_admin_recharge_sales: "v1/auth/sales/admin-recharge-sales",
  sales_assign_user: "v1/auth/sales/assign-user",
  sales_admin_collect_balance: "v1/auth/sales/admin-collect-balance",
  sales_collected_report: "v1/auth/sales/collected-report",
  sales_create: "v1/auth/sales/create",
  sales_delete_transaction: "v1/auth/sales/delete-transaction",
  sales_delete: "v1/auth/sales/delete",
  sales_sales_collected_from_users_tn:
    "v1/auth/sales/sales-collected-from-users-trn",
  sales_list: "v1/auth/sales/list",
  sales_admin_sales_transactions: "v1/auth/sales/admin-sales-transactions",
  sales_update_password: "v1/auth/sales/update-password",
  sales_edit: "v1/auth/sales/edit",
  create_advertisement: "v1/auth/create-advertisement",
  edit_admin_support: "v1/auth/edit-admin-support",
  list_advertisement: "v1/auth/list-advertisement",
  send_custom_message_to_all_user: "v1/auth/send-custom-message-to-all-user",
  create_static_page: "v1/auth/create-static-page",
  edit_static_page: "v1/auth/edit-static-page",
  change_user_status: "v1/auth/change-user-status",
  change_user_verify_status: "v1/auth/change-user-verify-status",
  create_admin_user: "v1/auth/create-admin-user",
  delete_user: "v1/auth/delete-user",
  edit_admin_user: "v1/auth/edit-admin-user",
  list_user_transactions_csv: "v1/auth/list-user-transactions-csv",
  list_user_wallet_transactions_cv: "v1/auth/list-user-wallet-transactions-csv",
  list_card_redeem_using_transactiond:
    "v1/auth/list-card-redeem-using-transactionId",
  list_user_transactions_filter: "v1/auth/list-user-transactions-filter",
  list_user_transactions: "v1/auth/list-user-transactions",
  list_user: "v1/auth/list-user",
  user_transactions: "v1/auth/user-transactions",
  list_user_trn_list: "v1/auth/list-user-trn-list",
  list_user_wallet_transactions: "v1/auth/list-user-wallet-transactions",
  list_user_wallet_transactions_filtr:
    "v1/auth/list-user-wallet-transactions-filter",
  filter_user: "v1/auth/filter-user",
  update_card_buy_limit: "v1/auth/update-card-buy-limit",
  add_user_coin: "v1/auth/add-user-coin",
  list_user_coin: "v1/auth/list-user-coin",
  remove_user_coin: "v1/auth/remove-user-coin",
  upload_card_redeem: "v1/auth/upload-card-redeem",
  create_company_pay_trn: "v1/auth/create-company-pay-trn",
  list_company_trn_list: "v1/auth/list-company-trn-list",
  find_admin_by_email: "v1/auth/find-admin-by-email",
  company_wallet: "v1/company-wallet",
  verify_otp: "v1/verify-otp",
  list_currency: "v1/list-currency",
  filter_currency: "v1/filter-currency",
  check_company_unique_field: "v1/check-company-unique-field",
  list_static_page: "v1/list-static-page",
  activity_log: "v1/auth/activity-logs",
  user_list_by_role: "v1/auth/list-user-by-role",
  admin_login: "v1/admin-login",

  freeze_user_list: "v1/auth/freeze-users",
  update_freeze_user: "v1/auth/user-unfreeze",
  anis_card_detail: "v1/auth/anis-card-detail",

  like_api_categories: "v1/auth/like-api-categories-detail",
  like_api_card: "v1/auth/like-api-cards",
  like_card_detail: "v1/auth/like-api-card-detail",
  setting_data: "v1/auth/setting-details",

  support_list:"v1/auth/list-supported-redeem",
  support_list_reply:"v1/auth/reply-support",
  support_list_comments:"v1/auth/list-supported-redeem-comments",

  get_expenses:"v1/auth/expenses",
  add_expenses:"v1/auth/expenses",
  edit_expenses:"v1/auth/expenses",
  delete_expenses:"v1/auth/expenses",
  report_expenses:"v1/auth/expenses/report",
  net_profit_report:"v1/auth/net-profit-report",

  financial_report:"v1/auth/financial-report",

  edit_inventory:"v1/auth/edit-inventory-limit",
  inventory_management_report:"v1/auth/inventory-management-report",

  list_sub_admin: "v1/auth/sub-admin",
  add_sub_admin: "v1/auth/sub-admin",
  change_sub_admin_status: "v1/auth/sub-admin",
  edit_sub_admin: "v1/auth/sub-admin",
};
