import { ENDPOINT } from '../../network/ENDPOINT';
import { callGetApiToServer, callApiToServer } from '../callApi';

export async function getAllSubAdmin(
  authtoken,
  skip,
  limit,
  searchData
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = `${ENDPOINT.list_sub_admin}/${skip}/${limit}?search_text=${searchData}`;

    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}