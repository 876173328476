import { callApiToServer } from '../callApi';

export async function createUser(
  authtoken,
  userObject
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
    let body = JSON.stringify({
        first_name: userObject.first_name,
        last_name: userObject.last_name,
        shop_name: userObject.shop_name,
        email: userObject.email,
        phone: userObject.phone,
        password: userObject.password,
        address_line1:userObject.address,
        note:userObject.note,
        salesman_id:userObject.selectSalesmanId,
        city:userObject.city || userObject.cityId
    });
  
    let endUrl = "v1/auth/create-admin-user";
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "POST", endUrl);
      
    return responseData;
      
  }catch(err) {
    throw err;
  }
}
