import { ENDPOINT } from "../network/ENDPOINT";

export const tracker = {
  [ENDPOINT.verify_otp]: "Verify OTP",
  [ENDPOINT.verify_redeem_otp]: "Verify Reedem OTP",
  [ENDPOINT.send_otp]: "Send OTP",
  [ENDPOINT.list_balance_requests]: "Balance requests list",
  [ENDPOINT.edit_advertisement]: "Edit advertisement",
  [ENDPOINT.get_advertisement]: "Get advertisement",
  [ENDPOINT.approve_balance_requests_id]: "Approve balance requests id",
  [ENDPOINT.update_max_instant_balance_id]: "Update max instant balance id",
  [ENDPOINT.add_card]: "Add card",
  [ENDPOINT.change_card_feature_status]: "Change card feature status",
  [ENDPOINT.change_card_status]: "Change card status",
  [ENDPOINT.delete_card_id]: "Delete card",
  [ENDPOINT.delete_card_inventory_searchtext]: "Delete card inventory ",
  [ENDPOINT.edit_card]: "Edit card",
  [ENDPOINT.fetch_api_categories_detail]: "Get api categories detail",
  [ENDPOINT.fetch_api_cards_id]: "Get api cards",
  [ENDPOINT.list_card]: "Card list",
  [ENDPOINT.list_card_redeem_using_cardid]: "Redeem card list",
  [ENDPOINT.list_company_card]: "Company card list",
  [ENDPOINT.filter_card]: "Filter card",
  [ENDPOINT.filter_company_card]: "Filter company card",
  [ENDPOINT.upload_single_card_redeem]: "Upload single redeem card ",
  [ENDPOINT.add_company]: "Add company",
  [ENDPOINT.change_company_password]: "Change company password",
  [ENDPOINT.change_company_status]: "Change company status",
  [ENDPOINT.delete_company]: "Delete company",
  [ENDPOINT.edit_company]: "Edit company",
  [ENDPOINT.edit_company_form_question]: "Edit company form question",
  [ENDPOINT.list_companies]: "Companies list",
  [ENDPOINT.filter_company]: "Filter company",
  [ENDPOINT.list_company_pay_trn]: "Company pay transaction list",
  [ENDPOINT.list_company_trn]: "Company transaction list",
  [ENDPOINT.search_all_company_trn_list]: "Search all company transaction list",
  [ENDPOINT.search_company_trn_list]: "Search company transaction list",
  [ENDPOINT.all_kpis]: "Dashboard",
  [ENDPOINT.add_balance_distributor]: "Add balance distributor",
  [ENDPOINT.create_distributor]: "Create distributor",
  [ENDPOINT.add_balance_user]: "Add balance user",
  [ENDPOINT.assign_user]: "Assign user",
  [ENDPOINT.edit_distributor]: "Edit distributor",
  [ENDPOINT.list_distributor]: "Distributor list",
  [ENDPOINT.distributor_transactions]: "Distributor transactions",
  [ENDPOINT.list_assigned_user]: "Assigned user list",
  [ENDPOINT.distributor]: "Distributor",
  [ENDPOINT.create_admin]: "Create admin",
  [ENDPOINT.change_employee_status]: "Change employee status",
  [ENDPOINT.edit_employee]: "Edit employee",
  [ENDPOINT.list_employee]: "Employee list",
  [ENDPOINT.filter_employee]: "Filter employee",
  [ENDPOINT.add_category]: "Add category",
  [ENDPOINT.change_category_status]: "Change category status",
  [ENDPOINT.delete_category]: "Delete category",
  [ENDPOINT.edit_category]: "Edit category",
  [ENDPOINT.list_category]: "Category list",
  [ENDPOINT.filter_category]: "Filter category",
  [ENDPOINT.add_city]: "Add city",
  [ENDPOINT.delete_city]: "Delete city",
  [ENDPOINT.list_city]: "City list ",
  [ENDPOINT.edit_city]: "Edit city",
  [ENDPOINT.add_coin]: "Add coin",
  [ENDPOINT.change_country_gcc_status]: "Change country gcc status",
  [ENDPOINT.change_coin_status]: "Change coin status",
  [ENDPOINT.delete_coin]: "Delete coin",
  [ENDPOINT.edit_coin]: "Edit coin",
  [ENDPOINT.list_coin]: "Coin list",
  [ENDPOINT.filter_coin]: "Filter coin",
  [ENDPOINT.add_country]: "Add country",
  [ENDPOINT.change_country_gcc_status]: "Change country gcc status",
  [ENDPOINT.change_country_status]: "Change country status",
  [ENDPOINT.delete_country]: "Delete country",
  [ENDPOINT.edit_country]: "Edit country",
  [ENDPOINT.list_country]: "Country list",
  [ENDPOINT.filter_country]: "Filter country",
  [ENDPOINT.create_currency]: "Create currency",
  [ENDPOINT.change_currency_status]: "Change currency status",
  [ENDPOINT.delete_currency]: "Delete currency",
  [ENDPOINT.edit_currency]: "Edit currency",
  [ENDPOINT.add_type]: "Add type",
  [ENDPOINT.change_type_status]: "Change type status",
  [ENDPOINT.delete_type]: "Delete type",
  [ENDPOINT.edit_type]: "Edit type",
  [ENDPOINT.list_type]: "Type list",
  [ENDPOINT.filter_type]: "Filter type",
  [ENDPOINT.list_balance_request_report]: "Balance request report list",
  [ENDPOINT.list_buy_card_report]: "Buy card report list",
  [ENDPOINT.sales_user_outstanding_report]: "Sales user outstanding report",
  [ENDPOINT.list_buy_max_card_sort]: "Buy max card sort list",
  [ENDPOINT.list_card_report]: "Card report list",
  [ENDPOINT.list_money_paid_to_company]: "Money paid to company list",
  [ENDPOINT.list_user_balance_report]: "User balance report list",
  [ENDPOINT.admin_profit_report]: "Admin profit report",
  [ENDPOINT.sales_admin_recharge_sales]: "Sales admin recharge sales",
  [ENDPOINT.sales_assign_user]: "Sales assign user",
  [ENDPOINT.sales_admin_collect_balance]: "Sales admin collect balance",
  [ENDPOINT.sales_collected_report]: "Sales collected report",
  [ENDPOINT.sales_create]: "Sales create",
  [ENDPOINT.sales_delete_transaction]: "Sales delete transaction",
  [ENDPOINT.sales_delete]: "Sales delete",
  [ENDPOINT.sales_sales_collected_from_users_trn]:
    "Sales collected from users transaction",
  [ENDPOINT.sales_list]: "Sales list",
  [ENDPOINT.sales_admin_sales_transactions]: "Sales admin sales transactions",
  [ENDPOINT.sales_update_password]: "Sales update password",
  [ENDPOINT.sales_edit]: "Sales edit",
  [ENDPOINT.create_advertisement]: "Create advertisement",
  [ENDPOINT.edit_admin_support]: "Edit admin support",
  [ENDPOINT.list_advertisement]: "Advertisement list",
  [ENDPOINT.send_custom_message_to_all_user]: "Send custom message to all user",
  [ENDPOINT.create_static_page]: "Create static page",
  [ENDPOINT.edit_static_page]: "Edit static page",
  [ENDPOINT.change_user_status]: "Change User status",
  [ENDPOINT.change_user_verify_status]: "Change user verify status",
  [ENDPOINT.create_admin_user]: "Create admin user",
  [ENDPOINT.delete_user]: "Delete user",
  [ENDPOINT.edit_admin_user]: "Edit admin user",
  [ENDPOINT.list_user_transactions_csv]: "User transactions csv list ",
  [ENDPOINT.list_user_wallet_transactions_csv]:
    "User wallet transactions csv list ",
  [ENDPOINT.list_card_redeem_using_transactionId]:
    "Card redeem using transactionId list ",
  [ENDPOINT.list_user_transactions_filter]: "User transactions filter list",
  [ENDPOINT.list_user_transactions]: "User transactions list ",
  [ENDPOINT.list_user]: "User list ",
  [ENDPOINT.user_transactions]: "User transactions",
  [ENDPOINT.list_user_trn_list]: "User transaction  list ",
  [ENDPOINT.list_user_wallet_transactions]: "User wallet transactions list ",
  [ENDPOINT.list_user_wallet_transactions_filter]:
    "User wallet transactions filter list ",
  [ENDPOINT.filter_user]: "Filter user",
  [ENDPOINT.update_card_buy_limit]: "Update card buy limit",
  [ENDPOINT.add_user_coin]: "Add user coin",
  [ENDPOINT.list_user_coin]: "User coin list ",
  [ENDPOINT.remove_user_coin]: "Remove user coin",
  [ENDPOINT.upload_card_redeem]: "Upload card redeem",
  [ENDPOINT.create_company_pay_trn]: "Create company pay transaction",
  [ENDPOINT.list_company_trn_list]: "Company transaction list ",
  [ENDPOINT.find_admin_by_email]: "Find admin by email",
  [ENDPOINT.company_wallet]: "Company wallet",
  [ENDPOINT.verify_otp]: "Verify otp",
  [ENDPOINT.list_currency]: "currency list ",
  [ENDPOINT.filter_currency]: "Filter currency",
  [ENDPOINT.check_company_unique_field]: "Check company unique field",
  [ENDPOINT.list_static_page]: "static page list ",
  [ENDPOINT.activity_log]: "Activity log ",
  [ENDPOINT.user_list_by_role]: "Get user list by role",
  [ENDPOINT.list_card_skip_limit]: "Card list",
  [ENDPOINT.admin_login]: "Login",
  [ENDPOINT.list_balance_requests_page_limit]: "Balance request list",

  [ENDPOINT.freeze_user_list]: "Like Freeze User",
  [ENDPOINT.update_freeze_user]: "Update Freeze User ",
  [ENDPOINT.anis_card_detail]: "Third Party Card Name",

  [ENDPOINT.like_api_categories]: "Like Api Categories",
  [ENDPOINT.like_api_card]: "Like Api Card",
  [ENDPOINT.like_card_detail]: "Like Card Details",
  [ENDPOINT.setting_data]: "List Settings ",

  [ENDPOINT.support_list]: "Ticket List Support ",
  [ENDPOINT.support_list_reply]: "Ticket Comments Support",
  [ENDPOINT.support_list_comments]: "View Ticket Comments",

  [ENDPOINT.get_expenses]: "List Expenses",
  [ENDPOINT.add_expenses]: "Add Expenses",
  [ENDPOINT.edit_expenses]: "Edit Expenses",
  [ENDPOINT.delete_expenses]: "Delete Expenses",
  [ENDPOINT.report_expenses]: "Report Expenses",
  [ENDPOINT.net_profit_report]: "Net Profit Report Expenses",

  [ENDPOINT.financial_report]: "Financial Report",

  [ENDPOINT.change_sub_admin_status]: "Change Sub Admin Status",
  [ENDPOINT.edit_sub_admin]: "Edit Sub Admin",
  [ENDPOINT.list_sub_admin]: " List Sub Admin",
  [ENDPOINT.add_sub_admin]: "Add Sub Admin",
};
