import { callGetApiToServer, deleteApiToServer } from "../callApi";

export async function deleteSalesUser(authtoken, id, cityId = "") {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let endUrl = `v1/auth/sales/delete/${id}?city=${cityId}`;

    let responseData = await deleteApiToServer(header, "DELETE", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
