import { Button, Input, notification, Select, Spin } from "antd";
import { Formik } from "formik";
import React, { Component } from "react";
import { alertError } from "../../utils/alert";
import { errorMessages } from "../../utils/validationMessages";
import ModalWrap from "../common/modal";
import { getLikeAPICard } from "../../Graphs/Card/getLikeAPICard";
import { getLikeAPICardCategory } from "../../Graphs/Card/getLikeAPICardCategory";
import { getLikeCardDetail } from "../../Graphs/Card/getLikeCardDetail";
import { getSettingDataAPI } from "../../Graphs/Setting/getSettingData";
import { StyleSelect } from "../../assets/css/style";

export default class LikeCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allThirdPartyCategoryOptions: [],
      thirdPartySubCategoryOptions: [],
      innerSubCategoryOptions: [],
      cardData: [],
      sigleCardDetail: null,
      disableSubCategory: false,
      apiCardPrice: 0,
      settingData: {},
      // apiCardSellPrice: 0,
    };
  }

  async componentDidMount() {
    this.getThirdPartyCardDetail();
    this.getSettingData();
    if (this.props.likeCardData?.like_card_id) {
      this.getCardByCardId();
    }
  }

  validateForm = (values) => {
    const errors = {};
    if (!values.thirdPartyCategory) {
      errors.thirdPartyCategory = errorMessages.thirdPartyCategory_required;
    }
    if (this.state.thirdPartySubCategoryOptions.length > 0) {
      if (!values.thirdPartySubCategory) {
        errors.thirdPartySubCategory =
          errorMessages.thirdPartySubCategory_required;
      }
    }
    if (this.state.innerSubCategoryOptions.length > 0) {
      if (!values.innerSubCategory) {
        errors.innerSubCategory = errorMessages.innerSubCategory_required;
      }
    }
    if (!values.name) {
      errors.name = errorMessages.card_required;
    }
    if (!values.likeAmount) {
      errors.likeAmount = errorMessages.amount_required;
    }
    return errors;
  };

  getSettingData = async () => {
    try {
      const { authToken } = this.props;
      let getSettingData = await getSettingDataAPI(authToken);

      if (getSettingData.status === 200) {
        this.setState({
          settingData: getSettingData.data,
        });
      } else {
        throw getSettingData.message ? getSettingData.message : "Error";
      }
    } catch (e) {
      alertError("CARD", e);
    }
  };

  getThirdPartyCardDetail = async () => {
    try {
      const { authToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let allthirdPartyData = await getLikeAPICardCategory(authToken);

      if (allthirdPartyData) {
        this.setState({
          isLoading: false,
          allThirdPartyCategoryOptions: allthirdPartyData.data.map((i) => ({
            ...i,
            label: i.categoryName,
            value: i.categoryName,
          })),
        });
      } else {
        throw allthirdPartyData ? allthirdPartyData.message : "Error";
      }
    } catch (e) {
      alertError("Third party card", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  getCardByCardId = async () => {
    try {
      const { authToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let cardData = await getLikeCardDetail(
        this.props.likeCardData.like_card_id,
        authToken
      );

      if (cardData) {
        this.setState({
          sigleCardDetail: cardData.data[0],
          isLoading: false,
          apiCardPrice: cardData.data[0].productPrice,
        });
      } else {
        throw cardData ? cardData.message : "Error";
      }
    } catch (e) {
      alertError("Third party card", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  async getCardData(id) {
    const { setCardData, authToken } = this.props;
    try {
      let allCard = await getLikeAPICard(authToken, id);
      //   await setCardData(allCard.data);

      this.setState({
        cardData: allCard.data.map((i) => ({
          ...i,
          label: i.productName,
          value: i.productName,
        })),
      });
    } catch (e) {
      console.log("error", e);
    } finally {
    }
  }

  handleSubmit = (values) => {
    let cardId = this.state.cardData.find(
      (i) => i.productName == values.name
    )?.productId;
    if (!cardId) {
      cardId = this.state.sigleCardDetail?.productId;
    }
    console.log("values", values);
    this.props.onSubmit({ ...values, cardId });
    this.props.onClose();
  };

  render() {
    const {
      isLoading,
      allThirdPartyCategoryOptions,
      thirdPartySubCategoryOptions,
      innerSubCategoryOptions,
      cardData,
      apiCardPrice,
      // apiCardSellPrice,
      sigleCardDetail,
    } = this.state;

    const { likeCardData } = this.props;

    return (
      <div>
        <ModalWrap
          visible={this.props.isVisible}
          closable={this.props.onClose}
          title={likeCardData ? "Edit Like Card " : "Add Like Card"}
          onOk={this.props.onClose}
          onCancel={this.props.onClose}
          isLoading={isLoading}
        >
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              thirdPartyCategory: likeCardData?.thirdPartyCategory ?? "",
              thirdPartySubCategory: likeCardData?.thirdPartySubCategory ?? "",
              innerSubCategory: likeCardData?.innerSubCategory ?? "",
              name: likeCardData?.name ?? sigleCardDetail?.productName ?? "",
              likeAmount: likeCardData?.likeAmount ?? "",
            }}
            validate={this.validateForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => {
              return isLoading ? (
                <div style={{ marginLeft: "20px" }}>
                  <Spin
                    size="large"
                    style={{ width: "100%", margin: "0 auto" }}
                  />
                </div>
              ) : (
                <div>
                  <div className="formik-field_wrap mt-5">
                    <div className="formik-field-left">
                      Third Party Category
                      <StyleSelect
                        value={values.thirdPartyCategory}
                        style={{ width: 200 }}
                        id="thirdPartyCategory"
                        name="thirdPartyCategory"
                        placeholder="Category Name"
                        onChange={(value) => {
                          setFieldValue("thirdPartyCategory", value);
                        }}
                        onBlur={() =>
                          setFieldTouched("thirdPartyCategory", true)
                        }
                        onSelect={(value) => {
                          let selectedData = allThirdPartyCategoryOptions.find(
                            (i) => i.value == value
                          );

                          if (selectedData.childs.length < 1) {
                            this.getCardData(selectedData.id);
                            this.setState({
                              thirdPartySubCategoryOptions: [],
                              disableSubCategory: true,
                            });
                          } else {
                            this.setState({
                              thirdPartySubCategoryOptions:
                                selectedData.childs.map((i) => ({
                                  ...i,
                                  label: i.categoryName,
                                  value: i.categoryName,
                                })),
                              disableSubCategory: false,
                            });
                          }
                          setFieldValue("thirdPartySubCategory", null);
                          setFieldValue("name", null);
                        }}
                        options={allThirdPartyCategoryOptions}
                      ></StyleSelect>
                      {errors.thirdPartyCategory && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.thirdPartyCategory}
                        </p>
                      )}
                    </div>
                    <div className="formik-field-right">
                      Third Party Sub Category
                      <StyleSelect
                        value={values.thirdPartySubCategory}
                        style={{ width: 200 }}
                        id="thirdPartySubCategory"
                        name="thirdPartySubCategory"
                        placeholder="Sub Category Name"
                        disabled={this.state.disableSubCategory}
                        onChange={(value) => {
                          setFieldValue("thirdPartySubCategory", value);
                        }}
                        onBlur={() =>
                          setFieldTouched("thirdPartySubCategory", true)
                        }
                        onSelect={(value) => {
                          let selectedData = thirdPartySubCategoryOptions.find(
                            (i) => i.value == value
                          );

                          if (selectedData.childs.length < 1) {
                            this.getCardData(selectedData.id);
                            this.setState({
                              innerSubCategoryOptions: [],
                              disableInnerSubCategory: true,
                            });
                          } else {
                            this.setState({
                              innerSubCategoryOptions: selectedData.childs.map(
                                (i) => ({
                                  ...i,
                                  label: i.categoryName,
                                  value: i.categoryName,
                                })
                              ),
                              disableInnerSubCategory: false,
                            });
                          }
                          setFieldValue("innerSubCategory", null);
                          setFieldValue("name", null);
                        }}
                        options={thirdPartySubCategoryOptions}
                      ></StyleSelect>
                      {errors.thirdPartySubCategory && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.thirdPartySubCategory}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Inner Sub Category
                      <StyleSelect
                        value={values.innerSubCategory}
                        style={{ width: 200 }}
                        id="innerSubCategory"
                        name="innerSubCategory"
                        placeholder="Sub Category Name"
                        disabled={this.state.disableInnerSubCategory}
                        onChange={(value) => {
                          setFieldValue("innerSubCategory", value);
                        }}
                        onBlur={() => setFieldTouched("innerSubCategory", true)}
                        onSelect={(value) => {
                          let selectedData = innerSubCategoryOptions.find(
                            (i) => i.value == value
                          );
                          this.getCardData(selectedData.id);
                        }}
                        options={innerSubCategoryOptions}
                      ></StyleSelect>
                      {errors.innerSubCategory && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.innerSubCategory}
                        </p>
                      )}
                    </div>
                    <div className="formik-field-right">
                      Name
                      <StyleSelect
                        value={values.name}
                        options={cardData.length < 0 ? "Loading..." : cardData}
                        style={{ width: 200 }}
                        onChange={(value) => {
                          setFieldValue("name", value);
                        }}
                        id="name"
                        name="name"
                        placeholder="Card Name"
                        onSelect={(value) => {
                          let selectedData = cardData.find(
                            (i) => i.value == value
                          );

                          this.setState({
                            apiCardPrice: selectedData.productPrice,
                          });
                        }}
                      ></StyleSelect>
                      {errors.name && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.name}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Price
                      <Input
                        id="likeAmount"
                        name="likeAmount"
                        style={{ width: 200 }}
                        type="number"
                        min={0}
                        value={values.likeAmount}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.likeAmount && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.likeAmount}
                        </p>
                      )}
                    </div>
                  </div>

                  {apiCardPrice != 0 && (
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Api card price : {apiCardPrice}
                      </div>
                      <div className="formik-field-right">
                        Total Price :{" "}
                        {(
                          this.state.settingData?.exchange_rate *
                          values.likeAmount
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}

                  <Button
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              );
            }}
          </Formik>
        </ModalWrap>
      </div>
    );
  }
}
