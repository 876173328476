import { callApiToServer } from "../callApi";

export async function editUser(authtoken, userObject, userId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      userId: userId,
      first_name: userObject.first_name,
      last_name: userObject.last_name,
      shop_name: userObject.shop_name,
      email: userObject.email,
      phone: userObject.phone,
      referal_code: userObject.referal_code,
      password: userObject.password,
      address_line1: userObject.address,
      city: userObject.cityId,
    });
    let endUrl = "v1/auth/edit-admin-user";
    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
