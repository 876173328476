import { Switch, Button, Divider, Checkbox, Row, Col } from "antd";
import * as React from "react";
import { Layout, Input, TimePicker, Select } from "antd";
import { Formik, FieldProps, Field } from "formik";
import * as moment from "moment";
import styled from "styled-components";
import { errorMessages } from "../../utils/validationMessages";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
} from "../../utils/validations";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";
import { StyleSelect } from "../../assets/css/style";
import { alertError } from "../../utils/alert";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      index: null,
      isLoading: false,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      cityData: [],
      roleType: "1",
      dashboard: {
        view: false,
        edit: false,
        delete: false,
      },
      master: {
        view: false,
        edit: false,
        delete: false,
      },
      card: {
        view: false,
        edit: false,
        delete: false,
      },
      user: {
        view: false,
        edit: false,
        delete: false,
      },
      sales: {
        view: false,
        edit: false,
        delete: false,
      },
      ticketSupport: {
        view: false,
        edit: false,
        delete: false,
      },
      expenses: {
        view: false,
        edit: false,
        delete: false,
      },
      defaultDashboard: [],
      defaultUser: [],
      defaultCard: [],
      defaultMaster: [],
      defaultBalanceRequest: [],
      defaultCompany: [],
      defaultReport: [],
      defaultSales: [],
      defaultTicketSupport: [],
      defaultExpenses: [],
      update_permission: null,
    };
  }

  input;

  componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }
    this.getCityList();
    // console.log("component did mount");

    // let access = JSON.parse(this.props.data.access_permission);

    // console.log("!!!!!!!!!!!ACCESS", access);

    // this.setState(
    //   {
    //     // dashboard: access.dashboard ? access.dashboard : {},
    //     master: access.master ? access.master : {},
    //     card: access.card ? access.card : {},
    //     user: access.user ? access.user : {},
    //     balanceRequest: access.balanceRequest ? access.balanceRequest : {},
    //     company: access.company ? access.company : {},
    //     reports: access.reports ? access.reports : {},
    //     sales: access.sales ? access.sales : {},
    //     ticketSupport: access.ticketSupport ? access.ticketSupport : {},
    //     expenses: access.expenses ? access.expenses : {},
    //   },
    //   () => {
    //     let defaultVal = {
    //       dashboard: [],
    //       master: [],
    //       card: [],
    //       user: [],
    //       balanceRequest: [],
    //       company: [],
    //       reports: [],
    //       sales: [],
    //       ticketSupport: [],
    //       expenses: [],
    //     };

    //     for (let k of [
    //       "dashboard",
    //       "master",
    //       "card",
    //       "user",
    //       "balanceRequest",
    //       "company",
    //       "reports",
    //       "sales",
    //       "ticketSupport",
    //       "expenses",
    //     ]) {
    //       if (access[k]) {
    //         if (access[k].view == true) {
    //           defaultVal[k].push("view");
    //         }
    //         if (access[k].edit == true) {
    //           defaultVal[k].push("edit");
    //         }
    //         if (access[k].delete == true) {
    //           defaultVal[k].push("delete");
    //         }
    //       }
    //     }
    //     this.setState(
    //       {
    //         defaultDashboard: defaultVal.dashboard ? defaultVal.dashboard : [],
    //         defaultMaster: defaultVal.master ? defaultVal.master : [],
    //         defaultCard: defaultVal.card ? defaultVal.card : [],
    //         defaultUser: defaultVal.user ? defaultVal.user : [],
    //         defaultBalanceRequest: defaultVal.balanceRequest
    //           ? defaultVal.balanceRequest
    //           : [],
    //         defaultCompany: defaultVal.company ? defaultVal.company : [],
    //         defaultReport: defaultVal.reports ? defaultVal.reports : [],
    //         defaultSales: defaultVal.sales ? defaultVal.sales : [],
    //         defaultTicketSupport: defaultVal.ticketSupport
    //           ? defaultVal.ticketSupport
    //           : [],
    //           defaultExpenses: defaultVal.expenses
    //           ? defaultVal.expenses
    //           : [],
    //       },

    //       () => {
    //         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!", this.state);
    //       }
    //     );
    //   }
    // );
    // this.setState({
    //     admin: this.props.data.role == "Admin" ? true : false
    // })
  }

  async getCityList() {
    try {
      let getCityList = await getCityListAPI(this.props.authToken);

      if (getCityList.status == 200) {
        let temp = getCityList.data.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        }));

        this.setState({
          cityData: [
            ...this.state.cityData,
            { label: "--- Select City ---", value: "Select" },
            ...temp,
          ],
        });
      } else {
        throw getCityList.message ? getCityList.message : "Error";
      }
    } catch (error) {
      alertError("User", error);

      return -1;
    }
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    console.log(`selected ${value}`);
    amenities = value;
  }

  onChangeCheckBox = (data, key) => {
    console.log(data);
    let obj = {
      update_permission: true,
    };

    obj[key] = {
      view: data.includes("view"),
      edit: data.includes("edit"),
      delete: data.includes("delete"),
    };

    console.log("!!!!!!!!checkbox", key, data);

    if (key == "dashboard") {
      this.setState({ defaultDashboard: data });
    } else if (key == "master") {
      console.log("!!!!!!!!master", key, data);
      this.setState({ defaultMaster: data });
    } else if (key == "card") {
      this.setState({ defaultCard: data });
    } else if (key == "user") {
      this.setState({ defaultUser: data });
    } else if (key == "balanceRequest") {
      this.setState({ defaultBalanceRequest: data });
    } else if (key == "company") {
      this.setState({ defaultCompany: data });
    } else if (key == "reports") {
      this.setState({ defaultReport: data });
    } else if (key == "sales") {
      this.setState({ defaultSales: data });
    } else if (key == "ticketSupport") {
      this.setState({ defaultTicketSupport: data });
    } else if (key == "expenses") {
      this.setState({ defaultExpenses: data });
    }

    this.setState(obj);
  };

  validateForm(values) {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    }
    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    return errors;
  }

  handleSubmit(values, action) {
    console.log("Basic Value== ", this.props.data.id);
    console.log("all vcal==", values);

    const editableValues = {      
        "first_name": values.first_name ,
        "last_name": values.last_name,
        "phone": values.phone,
        "city": values.cityId,
      }
    
    editableValues.subAdminId = this.props.data.id;

     // Static permission for sub admin
     const accessPermission= {"dashboard":{"view":true,"edit":true,"delete":false},"reports":{"view":true,"edit":true,"delete":false},"user":{"view":true,"edit":true,"delete":true},"employee":{"view":true,"edit":true,"delete":true},"distributor":{"view":true,"edit":true,"delete":true},"sales":{"view":true,"edit":true,"delete":true},"freeze-user":{"view":true,"edit":true,"delete":false},}

     editableValues.access_permission = JSON.stringify(accessPermission);

    // const {
    //   dashboard,
    //   user,
    //   master,
    //   card,
    //   balanceRequest,
    //   company,
    //   reports,
    //   sales,
    //   ticketSupport,
    //   expenses,
    //   update_permission,
    // } = this.state;

    // let access = {
    //   dashboard: dashboard,
    //   user: user,
    //   master: master,
    //   card: card,
    //   balanceRequest: balanceRequest,
    //   company: company,
    //   reports: reports,
    //   sales: sales,
    //   ticketSupport: ticketSupport,
    //   expenses: expenses,
    // };

    // values.access_permission = JSON.stringify(access);
    // values.employeeId = this.props.data.id;

    // values.update_permission = update_permission != null ? true : undefined;

    this.props.onSubmit(editableValues);

    this.props.handleCancel();

    this.setState({
      visible: false,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  handleSelectRole(value) {
    console.log("value", value);
    this.setState({
      update_permission: true,
      dashboard: {
        view: true,
        edit: true,
        delete: true,
      },
      master: {
        view: true,
        edit: true,
        delete: true,
      },
      card: {
        view: true,
        edit: true,
        delete: true,
      },
      user: {
        view: true,
        edit: true,
        delete: true,
      },
      balanceRequest: {
        view: true,
        edit: true,
        delete: true,
      },
      company: {
        view: true,
        edit: true,
        delete: true,
      },
      reports: {
        view: true,
        edit: true,
        delete: true,
      },
      sales: {
        view: true,
        edit: true,
        delete: true,
      },
      ticketSupport: {
        view: true,
        edit: true,
        delete: true,
      },
      expenses: {
        view: true,
        edit: true,
        delete: true,
      },
    });
  }

  render() {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!main", this.state.defaultMaster);

    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name: this.props.data.first_name,
                last_name: this.props.data.last_name,
                email: this.props.data.email,
                phone: this.props.data.phone,
                referal_code: this.props.data.referal_code,
                city:
                this.props.data?.user_city != null
                  ? this.props.data?.user_city?.name
                  : "",
                cityId:
                  this.props.data?.city != null ? this.props.data?.city : "",
                }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return (
                  <div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        First Name
                        <Input
                          id="first_name"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Last Name
                        <Input
                          id="last_name"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.last_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Phone
                        <Input
                          id="phone"
                          placeholder="User Phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "small",
                              margin: "0",
                            }}
                          >
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                      <div className="formik-field-right">
                        Email
                        <Input
                          id="email"
                          placeholder="Email"
                          disabled={true}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        City
                        <Field       
                          name="city"
                          render={({ field }) => (
                            <StyleSelect
                              style={{ width: "100%"}}
                              placeholder="Select City"
                              name="city"
                              defaultValue={values.city}
                              onBlur={() => setFieldTouched("city", true)}
                              value={values.city}
                              onSelect={(value, option) => {
                                setFieldValue("city", value);
                                setFieldValue("cityId", option.id);
                              }}
                              options={this.state.cityData}
                              {...field}
                            ></StyleSelect>
                          )}
                        />
                      </div>
                    </div>  

                    <Button
                      // type="primary"
                      style={{
                        marginTop: "19px",
                        background: "#5C6AC4",
                        color: "#fff",
                      }}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
