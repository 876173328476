import React, { Fragment, Component } from "react";
import Title from "./Title";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllEmployee } from "../../Graphs/Employee/listEmployee";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SearchHeader from "../common/header-component/searchHeader";
import { Spin, notification } from "antd";
import UserRecordsTable from "./UserRecordsTable";
import { AddEmployeeModal } from "./AddEmployeeModal";
import { alertError } from "../../utils/alert";

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeList: [],
      employeeTotal: 0,
      isLoading: false,
      primaryColor: "#4466f2",
    };
  }

  async componentDidMount() {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListEmployeeData,
        setListEmployeeDataTotal,
        setRouteName,
        setSearchText,
        loginUserData,
      } = this.props;
      let editPrimaryColor = await localStorage.getItem("primary_color");
      console.log("!!!!!!!!editPrimaryColor", editPrimaryColor);

      await setRouteName("EMPLOYEE");
      await setSearchText("");

      this.setState({
        isLoading: true,
        primaryColor: editPrimaryColor,
      });

      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : ""; // adding city for sub admin employee

      let employeeData = await getAllEmployee(authToken, 0, 10, city);

      if (employeeData.status == 200) {
        //set user data
        await setListEmployeeData(employeeData.data);
        await setListEmployeeDataTotal(employeeData.total);

        this.setState({
          employeeList: employeeData.data,
          employeeTotal: employeeData.total,
        });
      } else if (employeeData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw employeeData && employeeData.message
          ? employeeData.message
          : "Error";
      }
    } catch (e) {
      alertError("Employee", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  reloadData = async () => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListEmployeeData,
        setListEmployeeDataTotal,
        setRouteName,
        setSearchText,
        loginUserData,
      } = this.props;

      this.setState({
        isLoading: true,
      });

      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : ""; // adding city for sub admin employee

      let employeeData = await getAllEmployee(authToken, 0, 10, city);

      if (employeeData.status == 200) {
        //set user data
        await setListEmployeeData(employeeData.data);
        await setListEmployeeDataTotal(employeeData.total);

        this.setState({
          employeeList: employeeData.data,
          employeeTotal: employeeData.total,
        });
      } else if (employeeData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw employeeData && employeeData.message
          ? employeeData.message
          : "Error";
      }
    } catch (e) {
      alertError("Employee", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  generateCsv = async () => {
    try {
      const { authToken } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getAllEmployee(authToken, 0, 1000000)
            .then((res) => {
              let csvArray = [];

              // csvArray.push();

              // res.data.map((data) => {
              //     csvArray.push([
              //         data.first_name ? data.first_name : "NA",
              //         data.email ? data.email : "NA",
              //         data.phone ? data.phone : "NA",
              //         data.username ? data.username : "NA",
              //         data.wallet_balance ? String(data.wallet_balance) : "NA",
              //     ]);
              // });

              const rows = res.data;
              let csvName = "AllUsers.csv";
              let csvContent = "data:text/csv;charset=utf-8,";

              rows.forEach(function (rowArray) {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
              });

              var encodedUri = encodeURI(csvContent);
              // // // // window.open(encodedUri);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", csvName);
              document.body.appendChild(link); // Required for FF

              link.click();

              this.setState({
                isLoading: false,
              });
            })
            .catch((err) => {
              console.log("ERROR", err);
              throw err;
            });
        }
      );
    } catch (e) {
      console.log("error in generateCsv function", e);
    }
  };

  render() {
    const { authToken, loginUserData } = this.props;
    const { primaryColor, employeeList, isLoading, employeeTotal } = this.state;
    console.log(loginUserData, "loginUserDataloginUserDataloginUserData");

    const accessPermission =
      (loginUserData.access_permission &&
        JSON.parse(loginUserData.access_permission)) ||
      false;

    return loginUserData.role !== "Admin" &&
      (!accessPermission ||
        !accessPermission["employee"] ||
        accessPermission["employee"].view == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Title parent="Employee" title="employee" />
        <div
          style={{
            marginTop: "20px",
            display: "inline-block",
            width: "100%",
            marginBottom: "20px",
            paddingLeft: "14px",
            paddingRight: "55px",
          }}
        >
          {/* <div onClick={() => this.generateCsv()} style={{float : "left", cursor : "pointer"}}>
                                <div style={{width : "100px", backgroundColor : primaryColor, color : "white", padding : "7px 0px", textAlign : "center", borderRadius : "50px"}}>
                                    Export Csv
                                </div>
                            </div> */}
          <div style={{ float: "right", cursor: "pointer", width: "100%" }}>
            {loginUserData.role == "Admin" ||
            (accessPermission &&
              accessPermission["employee"] &&
              accessPermission["employee"].edit) ? (
              <AddEmployeeModal
                authToken={authToken}
                reloadData={this.reloadData}
                primaryColor={this.state.primaryColor}
                loginUserData={loginUserData}
              />
            ) : null}
          </div>
        </div>
        <div className="container-fluid">
          {isLoading ? (
            <div style={{ marginLeft: "20px" }}>
              <Spin
                size="large"
                style={{ marginLeft: "480px", marginTop: "130px" }}
              />
            </div>
          ) : (
            <div>
              <UserRecordsTable
                accessPermission={accessPermission}
                loginUserData={loginUserData}
                data={employeeList}
                primaryColor={primaryColor}
                employeeTotal={employeeTotal}
              />
            </div>
          )}

          <AddEmployeeModal
            admin={false}
            authToken={this.props.authToken}
            refx={(e) => (this.modalUserCoinsRef = e)}
            loginUserData={loginUserData}
            onClose="" //{this.handleDetailsModalclose.bind(this)}
            onSubmit="" //{this.editSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (employeeData) => {
      dispatch({
        type: "SET_USER_DATA",
        employeeData: employeeData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListEmployeeData: (listEmployeeData) => {
      dispatch({
        type: "SET_LIST_EMPLOYEE_DATA",
        listEmployeeData: listEmployeeData,
      });
    },
    setListEmployeeDataTotal: (listEmployeeDataTotal) => {
      dispatch({
        type: "SET_LIST_EMPLOYEE_DATA_TOTAL",
        listEmployeeDataTotal: listEmployeeDataTotal,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
    setSearchText: (searchText) => {
      dispatch({
        type: "SET_SEARCH_TEXT",
        searchText: searchText,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Employee));
