import { Button, notification, Spin } from "antd";
import * as React from "react";
import { Input, Layout } from "antd";
import { Form, Formik } from "formik";
import { findByEmail } from "../../Graphs/Employee/searchEmployee";
import { alertError } from "../../utils/alert";
import { errorMessages } from "../../utils/validationMessages";
import {
  emailValidation,
  mobileLengthValidation,
  mobileValidation,
} from "../../utils/validations";

const { Content } = Layout;
export class EditDistributorForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      data: [],
      view: false,
      isLoading: false,
      visible: false,
      errors: {},
    };
  }

  validateEmail = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });
      let getEmployee = await findByEmail(this.props.authToken, value);
      this.setState({
        isLoading: false,
      });

      if (getEmployee.status == 200) {
        if (getEmployee.data == null) {
          return true;
        } else {
          notification["warn"]({
            message: "Email Validation",
            description: "Email is already in used",
          });
          return false;
        }
      } else {
        return -1;
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("Distributor", error);

      return -1;
    }
  };

  validateForm(values) {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = errorMessages.first_name_required;
    }
    if (!values.last_name) {
      errors.last_name = errorMessages.last_name_required;
    }

    if (!values.phone) {
      errors.phone = errorMessages.mobile_required;
    } else if (mobileLengthValidation(values.phone)) {
      errors.phone = errorMessages.mobile_length_validation;
    } else if (mobileValidation(values.phone)) {
      errors.phone = errorMessages.mobile_validation;
    }

    return errors;
  }

  async handleSubmit(values, action) {
    const { loginUserData } = this.props;
    const city = loginUserData.role === 'Sub_admin' ? loginUserData.city : '';
    let body = { ...values, distributor_id: this.props.data.id };
    if (city) {
      body.city = city;
    }
    let result = await this.props.onSubmit(body);
    if (result) {
      this.props.handleCancel();
      this.setState({
        visible: false,
      });
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                first_name: data ? data.first_name : "",
                last_name: data ? data.last_name : "",
                email: data ? data.email : "",
                phone: data ? data.phone : "",
                commission: data ? data.commission : "",
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <Form>
                    {this.state.isLoading ? (
                      <div style={{ justifyContent: "center" }}>
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            First Name
                            <Input
                              id="first_name"
                              placeholder="First Name"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.first_name && touched.first_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.first_name}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Last Name
                            <Input
                              id="last_name"
                              placeholder="Last Name"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.last_name && touched.last_name ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.last_name}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Phone
                            <Input
                              id="phone"
                              placeholder="User Phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.phone}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Email
                            <Input
                              id="email"
                              placeholder="Email"
                              value={values.email}
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Percentage
                            <Input
                              id="commission"
                              type="commission"
                              placeholder="Commision"
                              value={values.commission}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <Button
                          style={{
                            marginTop: "19px",
                            background: "#5C6AC4",
                            color: "#fff",
                          }}
                          onClick={handleSubmit}
                        >
                          Update
                        </Button>{" "}
                        :
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Content>
        </div>
      </div>
    );
  }
}
