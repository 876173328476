import { callApiToServer } from "../callApi";

export async function changeStatus(authtoken, employeeId, status, city = "") {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = {
      employeeId: employeeId,
      flag: status,
    };

    if (city) {
      body.city = city;
    }

    body = JSON.stringify(body);

    let endUrl = "v1/auth/change-employee-status";
    console.log("endUrl printed here", endUrl);

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
