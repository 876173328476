import { ENDPOINT } from '../../network/ENDPOINT';
import { callApiToServer } from '../callApi';

export async function changeStatus(
  authtoken,
  subAdminId,
  status
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    };
      
    let body = JSON.stringify({
        employeeId: subAdminId,
        flag: status
    });

    console.log("cng bdy==",body)

    let endUrl = `${ENDPOINT.change_sub_admin_status}`;

    console.log("endUrl printed here", endUrl);
  
    let responseData = await callApiToServer(body, header, "PATCH", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}
