import { callGetApiToServer } from '../callApi';

export async function getAllUsers(
  authtoken,
  skipNumber,
  limitNumber,
  city = null,
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    // Conditionally append city query param if city is provided
    let endUrl = `v1/auth/list-user/${skipNumber}/${limitNumber}`;
    if (city) {
      endUrl += `?city=${city}`;
    }

    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
    return responseData;
  }catch(err) {
    throw err;
  }
}

export async function getUserWalletBalance(
  authtoken,
  id
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    let endUrl = "v1/user-wallet-balance/" + id;
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}