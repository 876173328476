import { callGetApiToServer, callApiToServer } from '../callApi';

export async function getAllEmployee(
  authtoken,
  skipNumber,
  limitNumber,
  city = null,
) {
  try{
    let header = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "authorization" : "Berear " + authtoken
    }
  
    // Conditionally append city query param if city is provided
    let endUrl = `v1/auth/list-employee/${skipNumber}/${limitNumber}`;
    if (city) {
      endUrl += `?city=${city}`;
    }
    console.log("endUrl printed here", endUrl);
  
    let responseData = await callGetApiToServer(header, "GET", endUrl);
      
    return responseData;
  }catch(err) {
    throw err;
  }
}