import { Button, Input, Select, Spin } from "antd";
import { Formik } from "formik";
import React, { Component } from "react";
import { getAnisCardDetail } from "../../Graphs/Card/getAnisCardDetail";
import { getAllThirdPartyCategoryData } from "../../Graphs/Card/getThirdPartCategory";
import { getAllThirdPartyCard } from "../../Graphs/Card/getThirdPartyCard";
import { alertError } from "../../utils/alert";
import { errorMessages } from "../../utils/validationMessages";
import {StyleSelect} from "../../assets/css/style";
import ModalWrap from "../common/modal";

export default class AnisCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      allThirdPartyCategoryOptions: [],
      thirdPartySubCategoryOptions: [],
      disableSubCategory: false,
      cardData: [],
      sigleCardDetail: null,
      apiCardPrice: 0,
      apiCardSellPrice: 0,
    };
  }

  async componentDidMount() {
    this.getThirdPartyCardDetail();
    if (this.props.anisCardData?.api_card_id) {
      this.getCardByCardId();
    }
  }

  validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = errorMessages.card_required;
    }

    if (!values.commission) {
      errors.commission = errorMessages.commission_require;
    }
    return errors;
  };

  getThirdPartyCardDetail = async () => {
    try {
      const { authToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let allthirdPartyData = await getAllThirdPartyCategoryData(authToken);

      if (allthirdPartyData) {
        this.setState({
          isLoading: false,
          allThirdPartyCategoryOptions: allthirdPartyData.data.map((i) => ({
            ...i,
            label: i.name,
            value: i.name,
          })),
        });
      } else {
        throw allthirdPartyData ? allthirdPartyData.message : "Error";
      }
    } catch (e) {
      alertError("Third party card", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  getCardByCardId = async () => {
    try {
      const { authToken } = this.props;

      this.setState({
        isLoading: true,
      });

      let cardData = await getAnisCardDetail(
        this.props.data.api_card_id,
        authToken
      );

      if (cardData.success) {
        this.setState({
          isLoading: false,
          sigleCardDetail: cardData.data,
          apiCardPrice: cardData.data.price,
          apiCardSellPrice:
            cardData.data.price +
            (cardData.data.price * this.props.anisCardData?.commission) / 100,
        });
      } else {
        throw cardData ? cardData.message : "Error";
      }
    } catch (e) {
      alertError("Third party card", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  async getCardData(id) {
    const { setCardData, authToken } = this.props;
    try {
      let allCard = await getAllThirdPartyCard(authToken, id);
      //   await setCardData(allCard.data);
      this.setState({
        cardData: allCard.data.cards.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        })),
      });
    } catch (e) {
      console.log("error", e);
    } finally {
    }
  }

  handleSubmit = (values) => {
    let cardId = this.state.cardData.find((i) => i.name == values.name)?.id;
    if (!cardId) {
      cardId = this.state.sigleCardDetail?.id;
    }
    this.props.onSubmit({ ...values, cardId });
    this.props.onClose();
  };

  render() {
    const {
      isLoading,
      allThirdPartyCategoryOptions,
      thirdPartySubCategoryOptions,
      cardData,
      apiCardPrice,
      apiCardSellPrice,
      sigleCardDetail,
    } = this.state;

    const { anisCardData } = this.props;

    return (
      <div>
        <ModalWrap
          visible={this.props.isVisible}
          closable={this.props.onClose}
          title={anisCardData ? "Edit Anis Card " : "Add Anis Card"}
          onOk={this.props.onClose}
          onCancel={this.props.onClose}
          isLoading={isLoading}
        >
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              thirdPartyCategory: anisCardData?.thirdPartyCategory ?? "",
              thirdPartySubCategory: anisCardData?.thirdPartySubCategory ?? "",
              name: anisCardData?.name ?? sigleCardDetail?.name ?? "",
              commission: anisCardData?.commission ?? "",
            }}
            validate={this.validateForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => {
              return isLoading ? (
                <div style={{ marginLeft: "20px" }}>
                  <Spin
                    size="large"
                    style={{ width: "100%", margin: "0 auto" }}
                  />
                </div>
              ) : (
                <div>
                  <div className="formik-field_wrap mt-5">
                    <div className="formik-field-left">
                      Third Party Category
                      <StyleSelect
                        value={values.thirdPartyCategory}
                        style={{ width: 220}}
                        id="thirdPartyCategory"
                        name="thirdPartyCategory"
                        placeholder="Category Name"
                        onChange={(value) => {
                          setFieldValue("thirdPartyCategory", value);
                        }}
                        onBlur={() =>
                          setFieldTouched("thirdPartyCategory", true)
                        }
                        onSelect={(value) => {
                          let selectedData = allThirdPartyCategoryOptions.find(
                            (i) => i.value == value
                          );

                          if (selectedData.subCategories.length < 1) {
                            this.getCardData(selectedData.id);
                            this.setState({
                              thirdPartySubCategoryOptions: [],
                              disableSubCategory: true,
                            });
                          } else {
                            this.setState({
                              thirdPartySubCategoryOptions:
                                selectedData.subCategories.map((i) => ({
                                  ...i,
                                  label: i.name,
                                  value: i.name,
                                })),
                              disableSubCategory: false,
                            });
                          }
                          setFieldValue("thirdPartySubCategory", null);
                          setFieldValue("name", null);
                        }}
                        options={allThirdPartyCategoryOptions}
                      ></StyleSelect>
                    </div>
                    <div className="formik-field-right">
                      Third Party Sub Category
                      <StyleSelect
                        value={values.thirdPartySubCategory}
                        style={{ width: 220 }}
                        id="thirdPartySubCategory"
                        name="thirdPartySubCategory"
                        placeholder="Sub Category Name"
                        disabled={this.state.disableSubCategory}
                        onChange={(value) => {
                          setFieldValue("thirdPartySubCategory", value);
                        }}
                        onBlur={() =>
                          setFieldTouched("thirdPartySubCategory", true)
                        }
                        onSelect={(value) => {
                          let selectedData = thirdPartySubCategoryOptions.find(
                            (i) => i.value == value
                          );
                          setFieldValue("name", null);
                          this.getCardData(selectedData.id);
                        }}
                        options={thirdPartySubCategoryOptions}
                      ></StyleSelect>
                    </div>
                  </div>

                  <div className="formik-field_wrap">
                    <div className="formik-field-left">
                      Name
                      <StyleSelect
                        value={values.name}
                        options={cardData.length < 0 ? "Loading..." : cardData}
                        style={{ width: 220 }}
                        onChange={(value) => {
                          setFieldValue("name", value);
                        }}
                        id="name"
                        name="name"
                        placeholder="Card Name"
                        onSelect={(value) => {
                          let selectedData = cardData.find(
                            (i) => i.value == value
                          );

                          if (anisCardData) {
                            let price =
                              selectedData.price +
                              (selectedData.price * anisCardData?.commission) /
                                100;

                            this.setState({
                              apiCardSellPrice: price,
                            });
                          }
                          this.setState({
                            apiCardPrice: selectedData.price,
                          });
                        }}
                      ></StyleSelect>
                      {errors.name && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.name}
                        </p>
                      )}
                    </div>
                    <div className="formik-field-right">
                      percentage
                      <Input
                        id="commission"
                        name="commission"
                        placeholder="Commision percentage"
                        type="number"
                        min={0}
                        value={values.commission}
                        onChange={(e) => {
                          const percentage = e.target.value;
                          const price =
                            apiCardPrice + (apiCardPrice * percentage) / 100;
                          this.setState({
                            apiCardSellPrice: price,
                          });
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.commission && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "small",
                            margin: "0",
                          }}
                        >
                          {errors.commission}
                        </p>
                      )}
                    </div>
                  </div>

                  {apiCardPrice != 0 && (
                    <div className="formik-field_wrap">
                      <div className="formik-field-left">
                        Card price : {apiCardPrice}
                      </div>
                      <div className="formik-field-right">
                        Card sell price :{" "}
                        {apiCardSellPrice == 0
                          ? apiCardPrice
                          : apiCardSellPrice.toFixed(2)}
                      </div>
                    </div>
                  )}

                  <Button
                    style={{
                      marginTop: "19px",
                      background: "#5C6AC4",
                      color: "#fff",
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              );
            }}
          </Formik>
        </ModalWrap>
      </div>
    );
  }
}
