import { SET_LIST_SUB_ADMIN_DATA, SET_LIST_SUB_ADMIN_DATA_TOTAL } from "../constant/actionTypes";

const initial_state = {
    listSubAdminData : null,
    listSubAdminDataTotal: 0,
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case SET_LIST_SUB_ADMIN_DATA:
            return { ...state, loading: false, listSubAdminData: action.listSubAdminData };

        case SET_LIST_SUB_ADMIN_DATA_TOTAL:
            return { ...state, loading: false, listSubAdminDataTotal: action.listSubAdminDataTotal };
            
        default: return { ...state };
    }
}
