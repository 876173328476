import React, { Fragment, Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import MoneyPaidReport from "./MoneyPaidReport";
import ListCardReport from "./ListCardReport";
import ListUserBalanceReport from "./ListUserBalanceReport";
import ListBuyCardMaxReport from "./ListBuyCardMaxReport";
import { PieChart } from "react-feather";
import UserOutStandingBalanceReport from "./UserOutStandingBalanceReport";
import CollectAmountTransaction from "../sales/CollectAmountTransactionModal";
import ProfitReport from "../sales/ProfitReport";
import { alertError } from "../../utils/alert";
import ExpensesReport from "../expenses/ExpensesReport";
import NetProfitReport from "./NetProfitReport";
import FinancialReport from "./FinancialReport";
import InventoryManagementReport from "./InventoryManagementReport";

const reportData = [
  // { reportIndex: 0, 
  //   name: "Card Report", 
  //   component: <ListCardReport /> 
  // },
  // {
  //   reportIndex: 1,
  //   name: "User Balance Report",
  //   component: <ListUserBalanceReport />,
  // },
  // {
  //   reportIndex: 2,
  //   name: "Sales Report",
  //   component: <ListBuyCardMaxReport />,
  // },
  // {
  //   reportIndex: 3,
  //   name: "Amount Transfer Report",
  //   component: <MoneyPaidReport />,
  // },
  // {
  //   reportIndex: 4,
  //   name: "User Outstanding Balance Report",
  //   component: <UserOutStandingBalanceReport />,
  // },
  // {
  //   reportIndex: 5,
  //   name: "Daily Collection From Salesman",
  //   component: <CollectAmountTransaction />,
  // },
  // {
  //   reportIndex: 6,
  //   name: "Profit Report",
  //   component: <ProfitReport />,
  // },
  // {
  //   reportIndex: 7,
  //   name: "Expenses Report",
  //   component: <ExpensesReport />,
  // },
  // {
  //   reportIndex: 8,
  //   name: "Net Profit Report",
  //   component: <NetProfitReport />,
  // },
  // {
  //   reportIndex: 9,
  //   name: "Financial Report",
  //   component: <FinancialReport />,
  // },
  // {
  //   reportIndex: 10,
  //   name: "Inventory Management Report",
  //   component: <InventoryManagementReport />,
  // },
];
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedReportIndex: -1,
    };
  }

  async componentDidMount() {
    try {
      const { setRouteName } = this.props;
      this.setState({
        isLoading: true,
      });
      await setRouteName("REPORT");
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  onChangeReportIndex = (index) => {
    this.setState({
      selectedReportIndex: index,
    });
  };

  render() {
    const { selectedReportIndex } = this.state;
    const { loginUserData } = this.props;
    const accessPermission =
      (loginUserData.access_permission &&
        JSON.parse(loginUserData.access_permission)) ||
      false;

    const selectedReport = reportData[selectedReportIndex.reportIndex] || {};

    const breadcrumbParent = selectedReport.name || "Reports";

    return loginUserData.role !== "Admin" &&
      (!accessPermission ||
        !accessPermission["dashboard"] ||
        accessPermission["dashboard"].view == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Breadcrumb
          parent={breadcrumbParent}
          title="Reports"
          icon={<PieChart />}
          url="/reports"
        />
        <div style={{ width: "100%", display: "inline-block" }}>
          {reportData.map((item, index) => {
            return (
              <div
                onClick={() => this.onChangeReportIndex(item)}
                style={{
                  cursor: "pointer",
                  borderRadius: "10px",
                  padding: "10px",
                  paddingTop: "25px",
                  textAlign: "center",
                  float: "left",
                  width: "23%",
                  marginRight: "15px",
                  marginBottom: "15px",
                  height: "70px",
                  background:
                    selectedReportIndex.reportIndex == item.reportIndex
                      ? "#3949ab"
                      : "#FFF",
                  color:
                    selectedReportIndex.reportIndex == item.reportIndex
                      ? "#FFF"
                      : "#000",
                  boxShadow:
                    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                }}
              >
                {item.name}
              </div>
            );
          })}
          <div style={{ display: "inline-block", width: "100%" }}>
            {reportData[selectedReportIndex.reportIndex]?.component}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report));
