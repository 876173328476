import { Button, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { Component, Fragment } from "react";
import Title from "../User/Title";
import AccountantTracker from "./AccountantTracker";
import AdminTracker from "./AdminTracker";
import CompanyTracker from "./CompanyTracker";
import DistributorActivity from "./DistributorActivity";
import EmployeeTracker from "./EmployeeTracker";
import { Activity } from "react-feather";
import SubAdminTracker from "./SubAdminTracker";
// import "./style.css";

class TrackUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      primaryColor: "#4466f2",
      selectedTab: "Admin",
    };
  }

  handleTabChange = (key) => {
    let selectedTab;
    switch (key) {
      case "1":
        selectedTab = "Admin";
        break;
      case "2":
        selectedTab = "Distributor";
        break;
      case "3":
        selectedTab = "Company";
        break;
      case "4":
        selectedTab = "Employee";
        break;
      case "5":
        selectedTab = "Accountant";
        break;
      case "6":
        selectedTab = "Sub Admin";
        break;  
      default:
        selectedTab = "Admin";
    }

    this.setState({
      selectedTab,
    });
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <Fragment>
        <div style={{ padding: "15px" }}>
          <Title
            parent={selectedTab}
            title="Activity"
            icon={<Activity />}
            url="/activity"
          />

          <Tabs
            defaultActiveKey="1"
            size="middle"
            onChange={this.handleTabChange}
            className="tabs-wrap"
          >
            {/* <Tabs destroyInactiveTabPane="true"> */}
            <TabPane tab="Admin" key="1">
              <AdminTracker />
            </TabPane>
            <TabPane tab="Distributor" key="2">
              <DistributorActivity />
            </TabPane>
            <TabPane tab="Company" key="3">
              <CompanyTracker />
            </TabPane>
            <TabPane tab="Employee" key="4">
              <EmployeeTracker />
            </TabPane>
            <TabPane tab="Accountant" key="5">
              <AccountantTracker />
            </TabPane>
            <TabPane tab="Accountant" key="6">
              <SubAdminTracker />
            </TabPane>
            {/* </Tabs> */}
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

export default TrackUser;
