//CUSTOMIZER 
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

//LOGIN AND COMMAN (auth)
export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_AUTHTOKEN = "SET_USER_AUTHTOKEN";
export const SET_ROUTE_NAME = "SET_ROUTE_NAME";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_LOADER = "SET_SEARCH_LOADER";
export const SET_OTP_VERIFICATION = "SET_OTP_VERIFICATION";



//USER TABLE (user)
export const SET_LIST_USER_DATA = "SET_LIST_USER_DATA";
export const SET_LIST_USER_DATA_TOTAL = "SET_LIST_USER_DATA_TOTAL";

//MASTER TABLE (master)
export const SET_LIST_COUNTRY_DATA = "SET_LIST_COUNTRY_DATA";
export const SET_LIST_COUNTRY_DATA_TOTAL = "SET_LIST_COUNTRY_DATA_TOTAL";
export const SET_LIST_CATEGORY_DATA = "SET_LIST_CATEGORY_DATA";
export const SET_LIST_CATEGORY_DATA_TOTAL = "SET_LIST_CATEGORY_DATA_TOTAL";
export const SET_LIST_TYPE_DATA = "SET_LIST_TYPE_DATA";
export const SET_LIST_TYPE_DATA_TOTAL = "SET_LIST_TYPE_DATA_TOTAL";
export const SET_LIST_COIN_DATA = "SET_LIST_COIN_DATA";
export const SET_LIST_COIN_DATA_TOTAL = "SET_LIST_COIN_DATA_TOTAL";
export const SET_LIST_CITY_DATA = "SET_LIST_CITY_DATA";
export const SET_LIST_CITY_DATA_TOTAL = "SET_LIST_CITY_DATA_TOTAL";

//CARD TABLE (card)
export const SET_LIST_CARD_DATA = "SET_LIST_CARD_DATA";
export const SET_LIST_CARD_DATA_TOTAL = "SET_LIST_CARD_DATA_TOTAL";
export const SET_THIRD_PARTY_CARD_DATA = "SET_THIRD_PARTY_CARD_DATA";

//CARD TABLE (card)
export const SET_LIST_TRANSACTION_DATA = "SET_LIST_TRANSACTION_DATA";
export const SET_LIST_TRANSACTION_DATA_TOTAL = "SET_LIST_TRANSACTION_DATA_TOTAL";

//USERS COINS TABLE (users_coins)
export const SET_LIST_USER_COIN_DATA = "SET_LIST_USER_COIN_DATA";
export const SET_LIST_USER_COIN_DATA_TOTAL = "SET_LIST_USER_COIN_DATA_TOTAL";

//ADMINS TABLE (admins)
export const SET_LIST_EMPLOYEE_DATA = "SET_LIST_EMPLOYEE_DATA";
export const SET_LIST_EMPLOYEE_DATA_TOTAL = "SET_LIST_EMPLOYEE_DATA_TOTAL";

//SUB ADMIN TABLE
export const SET_LIST_SUB_ADMIN_DATA = "SET_LIST_SUB_ADMIN_DATA";
export const SET_LIST_SUB_ADMIN_DATA_TOTAL = "SET_LIST_SUB_ADMIN_DATA_TOTAL";


//Static_pages TABLE (static_page)
export const SET_LIST_STATIC_PAGE_DATA = "SET_LIST_STATIC_PAGE_DATA";
export const SET_LIST_STATIC_PAGE_DATA_TOTAL = "SET_LIST_STATIC_PAGE_DATA_TOTAL";

export const SET_LIST_CURRENCY_DATA = 'SET_LIST_CURRENCY_DATA';
export const SET_LIST_CURRENCY_DATA_TOTAL = 'SET_LIST_CURRENCY_DATA_TOTAL';

export const SET_LIST_COMPANY_DATA = 'SET_LIST_COMPANY_DATA';
export const SET_LIST_COMPANY_DATA_TOTAL = 'SET_LIST_COMPANY_DATA_TOTAL';

export const SET_LIST_COMPANY_TRN_DATA = "SET_LIST_COMPANY_TRN_DATA";
export const SET_LIST_COMPANY_TRN_DATA_TOTAL = "SET_LIST_COMPANY_TRN_DATA_TOTAL";

//Balance Request 
export const SET_BALANCE_REQUEST_DATA = "SET_BALANCE_REQUEST_DATA";
export const SET_BALANCE_REQUEST_DATA_TOTAL = "SET_BALANCE_REQUEST_DATA_TOTAL";

//distributor 
export const SET_DISTRIBUTOR_DATA = "SET_DISTRIBUTOR_DATA";
export const SET_DISTRIBUTOR_DATA_TOTAL = "SET_DISTRIBUTOR_DATA_TOTAL";
export const SET_DISTRIBUTOR_BALANCE = "SET_DISTRIBUTOR_BALANCE"

// sales

export const SET_SALES_DATA = "SET_SALES_DATA";
export const SET_SALES_DATA_TOTAL = "SET_SALES_DATA_TOTAL";

export const SET_EXPENSES_DATA = "SET_EXPENSES_DATA";
export const SET_EXPENSES_DATA_TOTAL = "SET_EXPENSES_DATA_TOTAL";
