import * as React from "react";
import { Modal, Spin, notification } from "antd";
import { AddSubAdminForm } from "./AddSubAdminForm";

import { addSubAdminAPI } from "../../Graphs/SubAdmin/addSubAdmin";
import { alertError } from "../../utils/alert";
import { getCityListAPI } from "../../Graphs/Master/City/getCityListAPI";

export class AddSubAdminModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
      cityData: [{ label: "--- Select City ---", value: "Select" }],
      coinsList: [],
    };
  }

  async componentDidMount() {
    if (this.props.refx) {
      this.props.refx(this);
    }

    await this.getCoinsList();
    this.getCityList();
  }

  addSubmit = async (value) => {
    try {
      this.setState({
        isLoading: true,
      });

      let createObject = value;

      let coinData = await addSubAdminAPI(this.props.authToken, createObject);

      if (coinData.status == 200) {
        this.setState({
          isLoading: true,
        });

        notification["success"]({
          message: "Sub Admin",
          description: "Successfully Added",
        });

        this.props.reloadData();

        return 1;
      } else {
        throw coinData.message ? coinData.message : "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("Sub Admin",error)
      return -1;
    }
  };

  async getCityList() {
    try {
      let getCityListData = await getCityListAPI(this.props.authToken);

      if (getCityListData.status == 200) {
        let temp = getCityListData.data.map((i) => ({
          ...i,
          label: i.name,
          value: i.name,
        }));
        this.setState({
          cityData: [...this.state.cityData, ...temp],
        });
      } else {
        throw getCityListData.message ? getCityListData.message : "Error";
      }
    } catch (error) {
      alertError("SALES", error);
      return -1;
    }
  }

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = {}; // await ( this.props.authToken, 0, 100000);
      this.setState({
        coinsList: coinData.data,
      });
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.hide();
  };

  show = () => {
    this.setState({
      visible: true,
    });
  };

  hide() {
    this.setState({
      visible: false,
    });
    //this.props.onClose();
  }

  render() {
    const { primaryColor } = this.props;
    return (
      <React.Fragment>
        <div
          onClick={this.show}
          style={{
            cursor: "pointer",
            width: "100px",
            backgroundColor: primaryColor,
            color: "white",
            padding: "7px 0px",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          ADD
        </div>

        <Modal
          width="600px"
          closable={this.state.closable}
          open={this.state.visible}
          title="Add Sub Admin"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          destroyOnClose={true}
        >
          {this.state.isLoading ? (
            <div style={{ justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <AddSubAdminForm
              authToken={this.props.authToken}
              onClose={this.hide}
              onSubmit={this.addSubmit}
              handleCancel={this.handleCancel}
              cityData={this.state.cityData}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
