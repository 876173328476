import * as React from "react";
import { Modal, Spin } from "antd";
import { EditForm } from "./EditForm";
import ModalWrap from "../common/modal";

export class EditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      recordDetails: {},
      isLoading: false, // Fixme this should be true
      closable: true,
    };
  }

  componentDidMount() {}

  handleCancel = (e) => {
    this.props.onClose();
  };

  render() {
    return (
      <ModalWrap
        visible={this.props.cardSelectedData}
        closable={this.handleCancel}
        title="Edit Card"
        onOk={this.handleCancel}
        onCancel={this.handleCancel}
        isLoading={this.state.isLoading}
        // width="800px"
      >
        <EditForm
          loginUserData={this.props.loginUserData}
          data={this.props.cardSelectedData}
          authToken={this.props.authToken}
          onSubmit={this.props.onSubmit}
          handleCancel={this.handleCancel}
        />
      </ModalWrap>
    );
  }
}
