import React, { Component, Fragment } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { notification, Spin } from "antd";
import { getDistributorList } from '../../Graphs/distributor/getDistributorList';
import Title from '../User/Title';
import { AddEmployeeModal } from '../Employee/AddEmployeeModal';
import DistributorTable from './DistributorTable';
import { AddDistributorModal } from './AddDistributorModal';
import { Globe } from 'react-feather';
import { alertError } from '../../utils/alert';

class Distributor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            distributorList: [],
            totalDistributor: 0,
            isLoading: false,
            primaryColor: "#4466f2"
        }
    }

    componentDidMount() {
        this.getDistributorDataList();
    }

    getDistributorDataList = async () => {
        try {
            const { authToken, history, setDistributorData, setTotalDistributorData, loginUserData } = this.props;
            let editPrimaryColor = await localStorage.getItem('primary_color');
            let page = 0;
            let limit = 10;
            let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

            let distributorData = await getDistributorList(authToken, page, limit, city);

            this.setState({
                isLoading: true,
                primaryColor: editPrimaryColor
            });
            if (distributorData.status == 200) {
                await setDistributorData(distributorData.data);
                await setTotalDistributorData(distributorData.total);

                this.setState({
                    distributorList: distributorData.data,
                    totalDistributor: distributorData.total,
                    isLoading: false,
                });
            } else if (distributorData.status == 401) {
                console.log("I amm 401");
                history.push(`${process.env.PUBLIC_URL}/login`);


            } else {
                throw (distributorData && distributorData.message) ? distributorData.message : "Error";
            }
        } catch (e) {
            alertError("Distributor",e)
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    reloadData = () => {
        this.getDistributorDataList();
    }
    render() {
        const { authToken, loginUserData } = this.props;
        const { accessPermission } = loginUserData;
        const { isLoading, distributorList, totalDistributor, primaryColor } = this.state;
        return (
            <Fragment>
                <Title parent="Distributor" title="distributor" icon={<Globe />} url="/distributor"/>
                <div style={{ marginTop: "20px", display: "inline-block", width: "100%", marginBottom: "20px", paddingLeft: "14px", paddingRight: "55px" }}>
                    <div style={{ float: "right", cursor: "pointer", width: "100%" }}>
                        {
                            (
                                loginUserData.role == "Admin") || (loginUserData.role == "Sub_admin") || (accessPermission && accessPermission["employee"] && accessPermission["employee"].edit)  // added subadmin permission for distributor
                                ?
                                <AddDistributorModal
                                    authToken={authToken}
                                    loginUserData={loginUserData}
                                    reloadData={this.reloadData}
                                    primaryColor={this.state.primaryColor}
                                />
                                : null
                        }

                    </div>
                </div>
                <div className="container-fluid">
                    {
                        isLoading
                            ?
                            <div style={{ marginLeft: "20px" }}>
                                <Spin
                                    size="large"
                                    style={{ marginLeft: "480px", marginTop: "130px" }}
                                />
                            </div>
                            :
                            <div>
                                <DistributorTable
                                    accessPermission={accessPermission}
                                    loginUserData={loginUserData}
                                    data={distributorList}
                                    distributorTotal={totalDistributor}
                                    primaryColor={primaryColor}
                                    authToken={authToken}
                                />
                            </div>
                    }

                </div>
            </Fragment>
        )
    }
}



const mapStateToProps = state => ({
    authToken: state.auth.authToken,
    loginUserData: state.auth.loginUserData
});

const mapDispatchToProps = (dispatch) => {
    return {
        setDistributorData: (distributorData) => {
            dispatch({
                type: 'SET_DISTRIBUTOR_DATA',
                distributorData: distributorData
            });
        },
        setTotalDistributorData: (totalDistributor) => {
            dispatch({
                type: 'SET_DISTRIBUTOR_DATA_TOTAL',
                totalDistributor: totalDistributor
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Distributor));

