import { DatePicker, Input, notification, Select } from "antd";
import { Chart } from "chart.js";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { getCardReport } from "../../Graphs/Report/getCardReport";
import moment from "moment";
import { getDistributorList } from "../../Graphs/distributor/getDistributorList";
import { alertError } from "../../utils/alert";
const { RangePicker } = DatePicker;

const options = [
  {
    value: "",
    label: "All",
  },
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "company",
    label: "Company",
  },
  {
    value: "API",
    label: "API",
  },
  {
    value: "like_api",
    label: "Like",
  },
];

class Acquisitions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      filter: "",
      distributorId: "",
      wholeData: [],
      distributorOptions: [],
    };
  }

  componentDidMount() {
    const { startDate, endDate, filter, distributorId } = this.state;
    this.getGraph(startDate, endDate, filter, distributorId);
    this.getDistributorDataList();
  }

  getStartDate() {
    const date = new Date();
    date.setDate(date.getDate() - 6);
    return date;
  }
  getEndDate() {
    const date = new Date();
    date.setDate(date.getDate());
    return date;
  }

  async getGraphData(startDate, endDate, filter, distributorId) {
    try {
      const { authToken } = this.props;

      let addCardReportData = await getCardReport(
        authToken,
        startDate,
        endDate,
        filter,
        distributorId
      );
      if (addCardReportData.status == 200) {
        let count = {};
        let countArray = {};
        let admin = {};
        let api = {};
        let company = {};
        let like = {};
        if (!addCardReportData) return null;

        addCardReportData.data.forEach((element) => {
          let momentObj = moment(new Date(element.createdAt)).utc();
          let newDate = momentObj.format("DD MMM YYYY");

          if (filter == "") {
            if (element.created_by == "admin") {
              admin[newDate] = (admin[newDate] || 0) + 1;
            }

            if (element.created_by == "Api") {
              api[newDate] = (api[newDate] || 0) + 1;
            }

            if (element.created_by == "company") {
              company[newDate] = (company[newDate] || 0) + 1;
            }

            if (element.created_by == "like_api") {
              like[newDate] = (like[newDate] || 0) + 1;
            }
          } else {
            if (newDate) {
              count[newDate] = (count[newDate] || 0) + 1;
            }
          }
        });
        countArray["admin"] = admin;
        countArray["api"] = api;
        countArray["company"] = company;
        countArray["like"] = like;
        return filter == "" ? countArray : count;
      }
    } catch (error) {
      console.log(error);
    }
  }

  getDistributorDataList = async () => {
    try {
      const { authToken, loginUserData } = this.props;
      let page = 0;
      let limit = 10;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      let distributorData = await getDistributorList(authToken, page, limit, city);

      if (distributorData.status == 200) {
        let distributor = distributorData.data.map((item) => {
          return {
            value: item.first_name + " " + item.last_name,
            label: item.first_name + " " + item.last_name,
            id: item.id,
          };
        });
        this.setState({
          distributorOptions: [
            { value: "", label: "All", id: "" },
            ...distributor,
          ],
        });
      } else if (distributorData.status == 401) {
        console.log("I amm 401");
      } else {
        throw distributorData && distributorData.message
          ? distributorData.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    }
  };

  async getGraph(startDate, endDate, filter, distributorId) {
    let data = await this.getGraphData(
      startDate,
      endDate,
      filter,
      distributorId
    );

    let combinedStats = {
      ...data.admin,
      ...data.api,
      ...data.company,
      ...data.like,
    };

    var keys = Object.keys(combinedStats).map((key) => {
      return key;
    });

    keys.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });

    new Chart(document.getElementById("acquisitions"), {
      type: "line",
      data: {
        labels:
          filter == ""
            ? keys
            : Object.keys(data).sort(function (a, b) {
                return new Date(a) - new Date(b);
              }),

        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Chart.js Line Chart",
            },
          },
          scales: {
            xAxes: [
              {
                type: "date",
              },
            ],
          },
        },
        datasets:
          filter == ""
            ? [
                {
                  label: "Admin",
                  data: keys.map((key) => {
                    if (data.admin[key]) {
                      return { x: key, y: data.admin[key] };
                    } else {
                      return { x: key, y: 0 };
                    }
                  }),
                  fill: false,
                  borderColor: "rgb(75, 192, 192)",
                  tension: 0.1,
                },
                {
                  label: "Company",
                  data: keys.map((key) => {
                    if (data.company[key]) {
                      return { x: key, y: data.company[key] };
                    } else {
                      return { x: key, y: 0 };
                    }
                  }),
                  fill: false,
                  borderColor: "red",
                  tension: 0.1,
                },
                {
                  label: "API",
                  data: keys.map((key) => {
                    if (data.api[key]) {
                      return { x: key, y: data.api[key] };
                    } else {
                      return { x: key, y: 0 };
                    }
                  }),
                  fill: false,
                  borderColor: "pink",
                  tension: 0.1,
                },
                {
                  label: "Like",
                  data: keys.map((key) => {
                    if (data.like[key]) {
                      return { x: key, y: data.like[key] };
                    } else {
                      return { x: key, y: 0 };
                    }
                  }),
                  fill: false,
                  borderColor: "blue",
                  tension: 0.1,
                },
              ]
            : [
                {
                  label: filter.charAt(0).toUpperCase() + filter.slice(1),
                  data: Object.values(data),
                  fill: false,
                  borderColor:
                    filter == "Admin"
                      ? "rgb(75, 192, 192)"
                      : filter == "company"
                      ? "red"
                      : filter == "like_api"
                      ? "blue"
                      : "pink",
                  tension: 0.1,
                },
              ],
      },
    });
  }

  onChangeDate = (date) => {
    let startDate = date[0].format("YYYY-MM-DD");
    let endDate = date[1].format("YYYY-MM-DD");

    if (!date.length) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });

    this.getGraph(
      startDate,
      endDate,
      this.state.filter,
      this.state.distributorId
    );
  };

  handleChange = (value) => {
    const { startDate, endDate, distributorId } = this.state;
    this.setState({
      filter: value,
    });
    this.getGraph(startDate, endDate, value, distributorId);
  };

  handleChangeDistributor = (value) => {
    let id = this.state.distributorOptions.find((i) => i.value == value).id;
    const { startDate, endDate, filter } = this.state;
    this.setState({
      distributorId: id,
    });
    this.getGraph(startDate, endDate, filter, id);
  };

  render() {
    console.log("filter", this.state.filter);
    return (
      <>
        <div style={{ float: "left", width: "100%", height:"32px", marginBottom:"25px", display: "flex" }}>
          <RangePicker
            format="DD-MM-YYYY"
            onChange={this.onChangeDate}
            disabledDate={(current) => {
              return moment().add(0, "days") <= current;
            }}
          />

          <div style={{ marginLeft: "15px", marginBottom: "15px" }}>
            <Select
              defaultValue="All"
              style={{
                width: " 150px",
              }}
              onChange={this.handleChange}
              options={options}
            />
          </div>

          <div style={{ marginLeft: "15px", marginBottom: "15px" }}>
            <Select
              showSearch
              defaultValue="All"
              style={{
                width: " 150px",
              }}
              onChange={this.handleChangeDistributor}
              options={this.state.distributorOptions}
            />
          </div>
        </div>
        <div style={{ width: "99%" }}>
          <canvas id="acquisitions"></canvas>
        </div>
      </>
    );
  }
} 

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(Acquisitions));
