import { callApiToServer } from "../callApi";

export async function editEmployee(authtoken, employeeObject, employeeId) {
  try {
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: "Berear " + authtoken,
    };

    let body = JSON.stringify({
      employeeId: employeeId,
      address_line1: employeeObject.address,
      ...employeeObject,
    });

    let endUrl = "v1/auth/edit-employee";

    let responseData = await callApiToServer(body, header, "POST", endUrl);

    return responseData;
  } catch (err) {
    throw err;
  }
}
