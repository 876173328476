import { Button, Divider, Popconfirm } from "antd";
import * as React from "react";
import { Layout, Input, Select, Spin } from "antd";
import { Formik, Field } from "formik";
import styled from "styled-components";
import { WEBURL, color } from "../../constant/comman";
import { PictureOutlined, FileImageOutlined } from "@ant-design/icons";
import { AddCardIcon } from "./AddCardIcon";
import * as la from "lodash";
import { getAllCategory } from "../../Graphs/Master/Category/listCategory";
import { getAllType } from "../../Graphs/Master/Type/listType";
import { getAllCountry } from "../../Graphs/Master/Country/listCountry";
import { getAllCoin } from "../../Graphs/Master/Coin/listCoin";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import AnisCardModal from "./AnisCardModal";
import LikeCardModal from "./LikeCardModal";
import { getAllCompany } from "../../Graphs/Company/listCompany";

const { Content } = Layout;
const Option = Select.Option;
const { TextArea } = Input;
var amenities = [];

const StyleDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 14px 0px;
  }
`;

export class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTime = this.handleTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleDetailsModalclose = this.handleDetailsModalclose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      allSubCategoryType: [],
      temp: [],
      locationList: [],
      recordSelectedtoView: null,
      data: [],
      view: false,
      viewLogo: false,
      viewImage: false,
      index: null,
      isLoading: true,
      popup: false,
      checked: false,
      item: null,
      filters: null,
      visible: false,
      inputVisible: false,
      inputValue: "",
      time: "",
      closeTime: "",
      closeTimeString: "",
      timeString: "",
      roleType: "1",
      openAnisModal: false,
      errors: {
        uploadImage: "",
        uploadLogo: "",
        startDate: "",
        endDate: "",
      },
      startDate: "",
      endDate: "",
      isPaid: false,
      isIconUpdate: false,
      isLogoUpdate: false,
      isImageUpdate: false,
      countryDataObject: {},
      countryData: null,
      coinDataObject: {},
      countryData: null,
      anisCardData: null,
      openLikeModal: false,
      likeCardData: null,
      cardData: [],
    };
  }

  input;
  selectedCountry = [];
  allColorType = [];
  selectedColorType = "";
  selctedColorKey = "";
  selectDisplayColorName = "";
  allCategoryType = [];
  selectedCategoryType = "";
  allCompanyType = [];
  selectedCategoryDisplayName = "";
  allTypeType = [];
  selectedTypeType = "";
  selectedTypeDisplayName = "";
  src = "";
  logoSrc = "";
  imageSrc = "";
  viewModalRef;
  logoModelRef;
  imageViewModalRef;
  isColorChanged = false;
  isCategoryChanged = false;
  isTypeChanged = false;
  allValidityType = [
    <Option key={"3"} value={"3"}>
      3 Months
    </Option>,
    <Option key={"6"} value={"6"}>
      6 Months
    </Option>,
    <Option key={"12"} value={"12"}>
      12 Months
    </Option>,
  ];
  selectedValidityType = "";
  isValidityNameChanged = false;
  selectedValidityDisplayname = "";
  allCoinsList = [];
  selectedCoin = "";
  selectedSubCategoryType = "";

  isCategoryData;
  isSubCategoryData;

  async componentDidMount() {
    if (this.props.data.api_card_id) {
      this.setState({
        anisCardData: {
          commission: this.props.data.commission,
          api_card_id: this.props.data.api_card_id,
        },
      });
    }
    if (this.props.data.like_card_id) {
      this.setState({
        likeCardData: {
          likeAmount: this.props.data.like_card_amount,
          like_card_id: this.props.data.like_card_id,
        },
      });
    }
    const { authToken } = this.props;
    const { countryDataObject, coinDataObject } = this.state;
    let dummyCountryDataObject = { ...countryDataObject };

    this.setState({
      isLoading: true,
    });

    let categoryData = await getAllCategory(authToken, 0, 100000000);
    let typeData = await getAllType(authToken, 0, 100000000);
    let countryData = await getAllCountry(authToken, 0, 100000000);
    let companyData = await getAllCompany(authToken, 0, 100000000);

    if (categoryData.status == 200) {
      this.setState({
        temp: categoryData.data,
      });

      categoryData.data.map((i) => {
        if (i.id == this.props.data.category_id) {
          this.isCategoryData = i.name;
          this.isSubCategoryData = "";
          this.selectedCategoryType = i.id;
          this.setState({
            allSubCategoryType:
              i.sub_categories != null && i.sub_categories.length
                ? i.sub_categories
                : [],
          });
        } else {
          if (i.sub_categories != null) {
            if (
              i.sub_categories.find((j) => j.id == this.props.data.category_id)
            ) {
              this.isCategoryData = i.name;
              this.isSubCategoryData = this.props.data.category.name;
              this.selectedSubCategoryType = this.props.data.category.id;
              this.setState({
                allSubCategoryType: i.sub_categories.length
                  ? i.sub_categories
                  : [],
              });
            }
          }
        }
      });
    }

    if (this.props.data.validity === 3) {
      this.selectedValidityDisplayname = "3 Months";
    } else if (this.props.data.validity === 6) {
      this.selectedValidityDisplayname = "6 Months";
    } else if (this.props.data.validity === 12) {
      this.selectedValidityDisplayname = "12 Months";
    }
    this.selectedValidityType = String(this.props.data.validity);

    la.map(color, (data, index) => {
      this.allColorType.push(
        <Option key={index} value={data.value}>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: data.value,
                float: "left",
                borderRadius: "5px",
              }}
            ></div>
            <span
              style={{
                float: "left",
                margin: "0px",
                padding: "0px",
                marginLeft: "10px",
                marginTop: "5px",
                lineHeight: "0px",
              }}
            >
              {data.name}
            </span>
          </div>
        </Option>
      );
    });

    categoryData &&
      categoryData.data &&
      categoryData.data.length > 0 &&
      la.map(categoryData.data, (subData, subIndex) => {
        if (subData.id === this.props.data.category_id) {
          this.selectedCategoryType = this.props.data.category_id;
          this.selectedCategoryDisplayName = subData.name;
        }
        this.allCategoryType.push(
          <Option key={subData.id} value={subData.id}>
            {subData.name}
          </Option>
        );
      });
      
      companyData &&
        companyData.data &&
        companyData.data.length > 0 &&
        la.map(companyData.data, (subData, subIndex) => {
          this.allCompanyType.push(
            <Option key={subData.id} value={subData.id}>
              {subData.name}
            </Option>
          );
        });

    typeData &&
      typeData.data &&
      typeData.data.length > 0 &&
      la.map(typeData.data, (subData, subIndex) => {
        if (subData.id === this.props.data.type_id) {
          this.selectedTypeType = this.props.data.type_id;
          this.selectedTypeDisplayName = subData.name;
        }
        this.allTypeType.push(
          <Option key={subData.id} value={subData.id}>
            {subData.name}
          </Option>
        );
      });

    this.selectedColorType = color[parseInt(this.props.data.color)].value;
    this.selectDisplayColorName = color[parseInt(this.props.data.color)].name;
    this.selctedColorKey = this.props.data.color;
    this.src =
      this.props.data.icon && this.props.data.icon !== null
        ? this.props.data.icon
        : "";
    this.logoSrc =
      this.props.data.logo && this.props.data.logo !== null
        ? this.props.data.logo
        : "";
    this.imageSrc =
      this.props.data.image && this.props.data.image !== null
        ? this.props.data.image
        : "";

    await this.getCoinsList();
    let dummyCoinDataObject = { ...coinDataObject };
    let coinData = this.allCoinsList;
    let newCoinData = [];

    let cardCoinData = this.props.data.card_coins;

    if (cardCoinData && cardCoinData.length) {
      coinData &&
        coinData.length > 0 &&
        la.map(coinData, (subData, subIndex) => {
          let cardCoin = cardCoinData.find((i) => i.coin_id == subData.id);

          if (cardCoin && cardCoin.id) {
            newCoinData.push({
              ...coinData[subIndex],
              ...{
                amount: cardCoin.amount,
                discount_amount: cardCoin.discount_amount,
              },
            });

            dummyCoinDataObject[subData.id] = {
              amount: cardCoin.amount,
              discount_amount: cardCoin.discount_amount,
            };
          } else {
            newCoinData.push({
              ...coinData[subIndex],
              ...{ amount: 0, discount_amount: 0 },
            });

            dummyCoinDataObject[subData.id] = {
              amount: 0,
              discount_amount: 0,
            };
          }
        });
    } else {
      coinData &&
        coinData.length > 0 &&
        la.map(coinData, (subData, subIndex) => {
          dummyCoinDataObject[subData.id] = {
            amount: 0,
            discount_amount: 0,
          };
        });
    }

    this.setState({
      isLoading: false,
      startDate:
        this.props.data.start_date == null ? "" : this.props.data.start_date,
      endDate: this.props.data.end_date == null ? "" : this.props.data.end_date,
      isPaid: this.props.data.is_paid,
      countryData: countryData.data,
      countryDataObject: dummyCountryDataObject,
      coinDataObject: dummyCoinDataObject,
      newCoinData: newCoinData,
    });
  }

  getCoinsList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      let coinData = await getAllCoin(this.props.authToken, 0, 100000);
      if (coinData.status == 200) {
        this.allCoinsList = coinData.data;
      }
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  uploadImage = (name) => {
    if (name != null) {
      this.src = name;
      this.setState({
        view: false,
        isIconUpdate: true,
      });
    } else {
      this.setState({
        view: false,
      });
    }
  };

  uploadLogo = (name) => {
    if (name != null) {
      this.logoSrc = name;
      this.setState({
        viewLogo: false,
        isLogoUpdate: true,
      });
    } else {
      this.setState({
        viewLogo: false,
      });
    }
  };

  uploadTypeImage = (name) => {
    if (name != null) {
      this.imageSrc = name;
      this.setState({
        viewImage: false,
        isImageUpdate: true,
      });
    } else {
      this.setState({
        viewImage: false,
      });
    }
  };

  showViewModal(params) {
    this.setState(
      {
        view: true,
      },
      () => {
        this.viewModalRef.show();
      }
    );
  }

  showLogoModal(params) {
    this.setState(
      {
        viewLogo: true,
      },
      () => {
        this.logoModelRef.show();
      }
    );
  }

  showImageViewModal(params) {
    this.setState(
      {
        viewImage: true,
      },
      () => {
        this.imageViewModalRef.show();
      }
    );
  }

  handleSelectValiditytype(value, option) {
    this.isValidityNameChanged = true;
    this.selectedValidityType = value;
  }

  handleSelectColortype(value, option) {
    this.selectedColorType = value;
    this.selctedColorKey = option.key;
    this.isColorChanged = true;
  }

  handleSelectCategorytype(value, option) {
    this.isCategoryChanged = true;
    this.selectedCategoryType = value;
    let subCategoryData = this.state.temp.find(
      (i) => i.id == value
    ).sub_categories;
    this.setState({
      allSubCategoryType:
        subCategoryData && subCategoryData.length ? subCategoryData : [],
    });
  }

  handleSelectCompanytype(value, option) {
    this.selectedCompanyType = value;
  }

  handleSelectSubCategorytype(value, option) {
    this.selectedSubCategoryType = value;
  }

  handleSelectTypetype(value, option) {
    this.selectedTypeType = value;
    this.isTypeChanged = true;
  }

  onChange(time, timeString) {
    console.log(time, timeString);
  }

  handleChange(value) {
    amenities = value;
  }

  validateForm = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.description) {
      errors.description = "Description is required";
    }

    if (this.selectedColorType === "") {
      errors.color = "Color is required";
    }

    if (
      !Array.isArray(values.allowed_coins) ||
      values.allowed_coins.length == 0
    ) {
      errors.allowed_coins = "Allow coin is required";
    }
    if (
      !Array.isArray(values.allowed_countries) ||
      values.allowed_countries.length == 0
    ) {
      errors.allowed_countries = "Allow country is required";
    }

    if (!this.state.anisCardData) {
      if (!(values.amount > 0)) {
        errors.amount = "Amount is required";
      }
    }

    return errors;
  };

  handleSelectCountry(value, option) {
    this.selectedCountry = value;
  }

  handleSubmit = (values, action) => {
    const {
      errors,
      isIconUpdate,
      isLogoUpdate,
      isImageUpdate,
      startDate,
      endDate,
      isPaid,
      anisCardData,
      likeCardData,
    } = this.state;
    let listErrors = { ...errors };

    values.start_date = startDate;
    values.end_date = endDate;
    values.color = this.selctedColorKey;
    values.category = this.selectedSubCategoryType
      ? this.selectedSubCategoryType
      : this.selectedCategoryType;
    values.company_id = this.selectedCompanyType;  
    values.icon = isIconUpdate ? this.src : "";
    values.logo = isLogoUpdate ? this.logoSrc : "";
    values.image = isImageUpdate ? this.imageSrc : "";
    values.validity = this.selectedValidityType;
    values.is_paid = isPaid;

    values.commission = anisCardData?.commission;
    values.api_card_id = anisCardData?.cardId ?? anisCardData?.api_card_id;

    values.like_card_amount =
      likeCardData?.likeAmount ?? likeCardData?.like_card_amount;
    values.like_card_id = likeCardData?.cardId ?? likeCardData?.like_card_id;

    values.allowed_coins = values.allowed_coins.join(",");
    values.allowed_countries = values.allowed_countries.join(",");
    this.props.onSubmit(values, this.props.data.id);
    this.props.handleCancel();

    this.setState({
      visible: false,
      anisCardData: null,
      likeCardData: null,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleTime(e, s) {
    this.setState({
      time: e,
      timeString: s,
    });
  }

  handleCloseTime(e, s) {
    this.setState({
      closeTime: e,
      closeTimeString: s,
    });
  }

  handleDetailsModalclose(item) {
    this.setState({
      view: false,
      lat: item.lat,
      long: item.lng,
    });
  }

  onPaidChange = (checked) => {
    this.setState({
      isPaid: checked,
    });
  };

  onstartDateChange = (date, dateString) => {
    this.setState({
      startDate: dateString,
    });
  };

  onEndDateChange = (date, dateString) => {
    this.setState({
      endDate: dateString,
    });
  };

  onCountryPriceChanged = (value, field, data) => {
    const { coinDataObject } = this.state;
    let dummyCoinDataObject = { ...coinDataObject };
    dummyCoinDataObject[data.id][field] = value;

    if (field === "amount") {
      dummyCoinDataObject[data.id]["amount"] = value;
    } else {
      dummyCoinDataObject[data.id]["discount_amount"] = value;
    }

    this.setState({
      coinDataObject: dummyCoinDataObject,
    });
  };

  findFilterNames(searchText, data) {
    let rgxp = new RegExp(searchText, "g");
    return data.filter((x) => x.label.match(rgxp));
  }

  render() {
    console.log("anisCardData", this.state.anisCardData);
    console.log("like ", this.state.likeCardData);

    const {
      isLoading,
      countryData,
      openAnisModal,
      anisCardData,
      likeCardData,
    } = this.state;
    const { loginUserData } = this.props;
    return (
      <div>
        <div>
          <Content style={{ background: "#fff", marginLeft: "10px" }}>
            <Formik
              initialValues={{
                name: this.props.data.name,
                description: this.props.data.description,
                color: this.props.data.color,
                category: this.isCategoryData,
                subcategory: this.isSubCategoryData,
                validity: String(this.props.data.validity),
                amount: this.props.data.amount,
                redeem_code: this.props.data.redeem_code,
                terms_and_condition: this.props.data.terms_and_condition,
                is_paid: this.props.data.is_paid,
                allowed_countries: this.props.data.allowed_countries
                  ? this.props.data.allowed_countries.split(",")
                  : [],
                allowed_coins: this.props.data.allowed_coins
                  ? this.props.data.allowed_coins.split(",")
                  : [],
                instructions: this.props.data.instructions || "",
                company_id: this?.props?.data?.company?.id ||'',
                company_amount: this.props.data.company_amount
                  ? this.props.data.company_amount
                  : 0,

                arabicName: this.props.data.arabic_name,
                cost_price: this.props.data.cost_amount,
                store_sell_price: this.props.data.store_sell_price,
              }}
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => {
                return isLoading ? (
                  <div style={{ marginLeft: "20px" }}>
                    <Spin
                      size="large"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <div
                        className="formik-field_wrap"
                        style={{
                          border: "1px solid blue",
                          textAlign: "left",
                          padding: "8px",
                          backgroundColor: "#0000ff26",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          className="formik-field-left"
                          style={{ marginTop: "5px" }}
                        >
                          {anisCardData
                            ? "Edit Anis Card Data"
                            : "Add Anis Card Data"}
                        </div>
                        <div
                          className="formik-field-right"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          {!anisCardData ? (
                            <Button
                              className=" btn-xs ml-5"
                              shape="circle"
                              icon={<PlusCircleOutlined />}
                              onClick={() =>
                                this.setState({
                                  openAnisModal: true,
                                })
                              }
                            />
                          ) : (
                            <>
                              <Button
                                className=" btn-xs ml-5"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={() =>
                                  this.setState({
                                    openAnisModal: true,
                                  })
                                }
                              />
                              <Popconfirm
                                onConfirm={() =>
                                  this.setState({
                                    anisCardData: null,
                                  })
                                }
                                title="Are you sure you want to delete card?"
                              >
                                <Button
                                  className=" btn-xs ml-3"
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </>
                          )}
                        </div>
                      </div>

                      <div
                        className="formik-field_wrap"
                        style={{
                          border: "1px solid #4fc3f7",
                          textAlign: "left",
                          padding: "8px",
                          backgroundColor: "aliceblue",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          className="formik-field-left"
                          style={{ marginTop: "5px" }}
                        >
                          {likeCardData
                            ? "Edit Like Card Data"
                            : "Add Like Card Data"}
                        </div>
                        <div
                          className="formik-field-right"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          {likeCardData ? (
                            <>
                              <Button
                                className=" btn-xs ml-5"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={() =>
                                  this.setState({
                                    openLikeModal: true,
                                  })
                                }
                              />
                              <Popconfirm
                                onConfirm={() =>
                                  this.setState({
                                    likeCardData: null,
                                  })
                                }
                                title="Are you sure you want to delete card?"
                              >
                                <Button
                                  className=" btn-xs ml-3"
                                  shape="circle"
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </>
                          ) : (
                            <Button
                              className=" btn-xs ml-5"
                              shape="circle"
                              icon={<PlusCircleOutlined />}
                              onClick={() =>
                                this.setState({
                                  openLikeModal: true,
                                })
                              }
                            />
                          )}
                        </div>
                      </div>

                      <hr style={{ marginTop: "30px" }} />

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Name
                          <Input
                            id="name"
                            name="name"
                            placeholder="Card Name"
                            value={values.name}
                            disabled={loginUserData.role == "Company"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Arabic Name
                          <Input
                            id="arabicName"
                            placeholder="Arabic Card Name"
                            name="arabicName"
                            value={values.arabicName}
                            onChange={handleChange}
                            disabled={
                              loginUserData && loginUserData.role === "Company"
                            }
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Description
                          <Input
                            id="description"
                            placeholder="Card Description"
                            value={values.description}
                            onChange={handleChange}
                            disabled={
                              loginUserData && loginUserData.role === "Company"
                            }
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.description}
                            </p>
                          ) : null}
                        </div>
                        {!(
                          loginUserData && loginUserData.role === "Company"
                        ) && (
                          <div className="formik-field-right">
                            Card Price
                            <Input
                              id="amount"
                              placeholder="Card Amount"
                              type="number"
                              min={0}
                              value={values.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {!values.thirdPartyCategory &&
                            errors.amount &&
                            touched.amount ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.amount}
                              </p>
                            ) : null}
                          </div>
                        )}
                      </div>

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Color
                            <Field
                              name="color"
                              render={({ field }) => (
                                <Select
                                  showSearch
                                  value={
                                    this.isColorChanged
                                      ? this.selectedColorType
                                      : this.selectDisplayColorName
                                  }
                                  defaultValue={values.color}
                                  placeholder="Select Color"
                                  defaultActiveFirstOption={false}
                                  showArrow={true}
                                  style={{ width: "100%" }}
                                  filterOption={false}
                                  onChange={(value) =>
                                    setFieldValue("color", value)
                                  }
                                  onBlur={() => setFieldTouched("color", true)}
                                  onSelect={(value, option) =>
                                    this.handleSelectColortype(value, option)
                                  }
                                >
                                  {this.allColorType}
                                </Select>
                              )}
                            />
                            {errors.color && touched.color ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.color}
                              </p>
                            ) : null}
                          </div>

                          <div className="formik-field-right">
                            Cost Price
                            <Input
                              id="cost_price"
                              placeholder="Cost Price"
                              value={values.cost_price}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={loginUserData.role == "Company"}
                            />
                          </div>
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Category
                            <Field
                              name="category"
                              render={({ field }) => (
                                <Select
                                  showSearch
                                  value={values.category}
                                  placeholder="Select Category"
                                  defaultActiveFirstOption={false}
                                  showArrow={true}
                                  style={{ width: "100%" }}
                                  filterOption={false}
                                  onChange={(value) =>
                                    setFieldValue("category", value)
                                  }
                                  onBlur={() =>
                                    setFieldTouched("category", true)
                                  }
                                  onSelect={(value, option) =>
                                    this.handleSelectCategorytype(value, option)
                                  }
                                >
                                  {this.allCategoryType}
                                </Select>
                              )}
                            />
                            {errors.category && touched.category ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.category}
                              </p>
                            ) : null}
                          </div>

                          <div className="formik-field-right">
                            SubCategory
                            <Field
                              name="subcategory"
                              render={({ field }) => (
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Choose SubCategory"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("subcategory", value);
                                  }}
                                  disabled={
                                    this.state.allSubCategoryType.length < 1
                                  }
                                  onBlur={() =>
                                    setFieldTouched("subcategory", true)
                                  }
                                  onSelect={(value, option) =>
                                    this.handleSelectSubCategorytype(
                                      value,
                                      option
                                    )
                                  }
                                >
                                  {this.state.allSubCategoryType.map((v) => (
                                    <Option key={v.id} value={v.id}>
                                      {v.name}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            />
                            {errors.subcategory && touched.subcategory ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.subcategory}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Coin
                            {this.selectedAllowCoins}
                            <Field
                              name="allowed_coins"
                              render={({ field }) => (
                                <Select
                                  mode="multiple"
                                  tokenSeparators={[","]}
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Choose coins"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("allowed_coins", value);
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("allowed_coins", true)
                                  }
                                  onSelect={(value, option) =>
                                    this.handleSelectCountry(value, option)
                                  }
                                >
                                  {this.allCoinsList &&
                                    this.allCoinsList.length &&
                                    this.allCoinsList.map((i) => {
                                      return (
                                        <option value={i.id}> {i.name}</option>
                                      );
                                    })}
                                </Select>
                              )}
                            />
                            {errors.allowed_coins && touched.allowed_coins ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.allowed_coins}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Country
                            <Field
                              name="allowed_countries"
                              render={({ field }) => (
                                <Select
                                  mode="multiple"
                                  tokenSeparators={[","]}
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Choose country"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("allowed_countries", value);
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("allowed_countries", true)
                                  }
                                >
                                  {countryData &&
                                    countryData.length &&
                                    countryData.map((i) => {
                                      return (
                                        <option value={i.id}> {i.name}</option>
                                      );
                                    })}
                                </Select>
                              )}
                            />
                            {errors.allowed_countries &&
                            touched.allowed_countries ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.allowed_countries}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div className="formik-field_wrap">
                          <div className="formik-field-left">
                            Instructions
                            <Input
                              id="instructions"
                              placeholder="Card Instructions"
                              value={values.instructions}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.instructions && touched.instructions ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.instructions}
                              </p>
                            ) : null}
                          </div>
                          <div className="formik-field-right">
                            Company
                            <Field
                              name="company_id"
                              render={({ field }) => (
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Choose Company"
                                  {...field}
                                  onChange={(value) => {
                                    setFieldValue("company_id", value);
                                  }}
                                  onBlur={() => setFieldTouched("company_id", true)}
                                  onSelect={(value, option) =>
                                    this.handleSelectCompanytype(value, option)
                                  }
                                >
                                  {this.allCompanyType}
                                </Select>
                              )}
                            />
                            {errors.company_id && touched.company_id ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.company_id}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="formik-field_wrap">
                        {loginUserData && loginUserData.role === "Company" ? (
                          <div className="formik-field-left">
                            Company Amount
                            <Input
                              id="company_amount"
                              placeholder="Company Amount"
                              value={values.company_amount}
                              onChange={handleChange}
                              type="number"
                              onBlur={handleBlur}
                            />
                            {errors.company_amount && touched.company_amount ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  margin: "0",
                                }}
                              >
                                {errors.company_amount}
                              </p>
                            ) : null}
                          </div>
                        ) : null}
                      </div>

                      <div className="formik-field_wrap">
                        <div className="formik-field-left">
                          Terms and Condition
                          <Input
                            id="terms_and_condition"
                            placeholder="Card Terms and Condition"
                            value={values.terms_and_condition}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.terms_and_condition &&
                          touched.terms_and_condition ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.terms_and_condition}
                            </p>
                          ) : null}
                        </div>
                        <div className="formik-field-right">
                          Store Price
                          <Input
                            id="store_sell_price"
                            placeholder="Store Price"
                            type="number"
                            min={0}
                            value={values.store_sell_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {!values.thirdPartyCategory &&
                          errors.store_sell_price &&
                          touched.store_sell_price ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {errors.store_sell_price}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {/* for edit logo */}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div
                          style={{
                            display: "inline-block",
                            width: "100%",
                            marginTop: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ float: "left", width: "10%" }}>
                            <PictureOutlined
                              style={{ width: "100%", fontSize: "30px" }}
                            />
                          </div>
                          <Button
                            style={{ width: "90%" }}
                            onClick={this.showLogoModal.bind(this)}
                          >
                            Choose Card Logo
                          </Button>
                          {this.logoSrc ? (
                            <p
                              style={{
                                fontSize: "small",
                                margin: "0",
                                marginLeft: "5px",
                              }}
                            >
                              {this.logoSrc}
                            </p>
                          ) : null}
                          {this.state.viewLogo ? (
                            <AddCardIcon
                              refx={(e) => (this.logoModelRef = e)}
                              isLogo={true}
                              onUpload={this.uploadLogo}
                            />
                          ) : null}
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div width="100%" style={{ marginTop: "10px" }}>
                          {this.state.errors.uploadLogo != "" ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.uploadLogo}
                            </p>
                          ) : null}
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div
                          style={{
                            marhinTop: "20px",
                            width: "400px",
                            borderRadius: "10px",
                          }}
                        >
                          {this.logoSrc && this.logoSrc !== "" ? (
                            <img
                              src={`${WEBURL}card/images/${this.logoSrc}`}
                              alt="CategoryIcon"
                              style={{ width: "100%" }}
                            />
                          ) : null}
                        </div>
                      )}

                      {/* edit icon  */}
                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div
                          style={{
                            display: "inline-block",
                            width: "100%",
                            marginTop: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ float: "left", width: "10%" }}>
                            <PictureOutlined
                              style={{ width: "100%", fontSize: "30px" }}
                            />
                          </div>
                          <Button
                            style={{ width: "90%" }}
                            onClick={this.showViewModal.bind(this)} //this.showViewModal.bind(this)
                          >
                            Choose Card Icon
                          </Button>
                          {this.src ? (
                            <p
                              style={{
                                fontSize: "small",
                                margin: "0",
                                marginLeft: "5px",
                              }}
                            >
                              {this.src}
                            </p>
                          ) : null}
                          {this.state.view ? (
                            <AddCardIcon
                              refx={(e) => (this.viewModalRef = e)}
                              onUpload={this.uploadImage}
                            />
                          ) : null}
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div width="100%" style={{ marginTop: "10px" }}>
                          {this.state.errors.uploadImage != "" ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.uploadImage}
                            </p>
                          ) : null}
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div
                          style={{
                            marhinTop: "20px",
                            width: "100px",
                            backgroundColor:
                              this.selectedColorType !== ""
                                ? this.selectedColorType
                                : "#ffffff",
                            borderRadius: "10px",
                          }}
                        >
                          {this.src && this.src !== "" ? (
                            <img
                              src={`${WEBURL}card/images/${this.src}`}
                              alt="CategoryIcon"
                              style={{ width: "100px" }}
                            />
                          ) : null}
                        </div>
                      )}

                      {/* edit image  */}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div
                          style={{
                            display: "inline-block",
                            width: "100%",
                            marginTop: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ float: "left", width: "10%" }}>
                            <PictureOutlined
                              style={{ width: "100%", fontSize: "30px" }}
                            />
                          </div>
                          <Button
                            style={{ width: "90%" }}
                            onClick={this.showImageViewModal.bind(this)}
                          >
                            Choose Card Image
                          </Button>
                          {this.imageSrc ? (
                            <p
                              style={{
                                fontSize: "small",
                                margin: "0",
                                marginLeft: "5px",
                              }}
                            >
                              {this.imageSrc}
                            </p>
                          ) : null}
                          {this.state.viewImage ? (
                            <AddCardIcon
                              refx={(e) => (this.imageViewModalRef = e)}
                              onUpload={this.uploadTypeImage}
                            />
                          ) : null}
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div width="100%" style={{ marginTop: "10px" }}>
                          {this.state.errors.uploadImage != "" ? (
                            <p
                              style={{
                                color: "red",
                                fontSize: "small",
                                margin: "0",
                              }}
                            >
                              {this.state.errors.uploadImage}
                            </p>
                          ) : null}
                        </div>
                      )}

                      {!(loginUserData && loginUserData.role === "Company") && (
                        <div
                          style={{
                            marhinTop: "20px",
                            width: "100px",
                            borderRadius: "10px",
                          }}
                        >
                          {this.imageSrc && this.imageSrc !== "" ? (
                            <img
                              src={`${WEBURL}card/images/${this.imageSrc}`}
                              alt="CategoryImage"
                              style={{ width: "100px" }}
                            />
                          ) : null}
                        </div>
                      )}

                      <Button
                        style={{
                          marginTop: "19px",
                          background: "#5C6AC4",
                          color: "#fff",
                        }}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </Content>
        </div>

        {openAnisModal && (
          <AnisCardModal
            isVisible={this.state.openAnisModal}
            onClose={() =>
              this.setState({
                openAnisModal: false,
              })
            }
            authToken={this.props.authToken}
            onSubmit={(values) => {
              this.setState({ anisCardData: values });
            }}
            anisCardData={anisCardData}
            data={this.props.data}
          />
        )}
        {this.state.openLikeModal && (
          <LikeCardModal
            isVisible={this.state.openLikeModal}
            onClose={() =>
              this.setState({
                openLikeModal: false,
              })
            }
            authToken={this.props.authToken}
            onSubmit={(values) => {
              this.setState({ likeCardData: values });
            }}
            likeCardData={likeCardData}
          />
        )}
      </div>
    );
  }
}
