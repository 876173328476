import { Button, notification, Spin, Switch, Table, Tooltip } from "antd";
import * as React from "react";
import {
  EditOutlined,
  EyeOutlined,
  TransactionOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { changeStatus } from "../../Graphs/Employee/changeStatus";
import { EditModal } from "./EditModal";
import { AddCoinInDistributor } from "./AddCoinInDistributor";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getDistributorList } from "../../Graphs/distributor/getDistributorList";
import { editDistributor } from "../../Graphs/distributor/editDistributor";
import UserCompanyTrn from "../User/UserCompanyTrn";
import DistributorTransaction from "./DistributorTransaction";
import { alertError } from "../../utils/alert";
import TableWrap from "../common/Table";

class DistributorTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      distributorData: [],
      totalDistributorData: 0,
      editDistributedData: null,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      userPermission: {},
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }
  modalRef;
  modalAddCoinDistributor;

  async componentDidMount() {
    const { setRouteName } = this.props;
    this.setState({
      distributorData: this.props.data,
      isLoading: false,
      editDistributedData: null,
      totalDistributorData: this.props.distributorTotal,
      // userTrasactionData: null
    });
    setRouteName("DISTRIBUTOR");
  }

  async getLimitedData(pagination) {
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        this.distributorTablePagination(start, end);
      }
    );
  }

  distributorTablePagination = async (start, end) => {
    try {
      const { authToken, history, setDistributorData, totalDistributor, loginUserData } =
        this.props;
      this.setState({
        isLoading: true,
      });

      let updateDistributorData;
      let page = start;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      updateDistributorData = await getDistributorList(authToken, page, end, city);

      if (updateDistributorData.status == 200) {
        this.setState({
          distributorData: updateDistributorData.data,
          totalDistributorData: updateDistributorData.total,
          isLoading: false,
        });

        await setDistributorData(updateDistributorData.data);
        await totalDistributor(updateDistributorData.total);
      } else if (updateDistributorData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw updateDistributorData && updateDistributorData.message
          ? updateDistributorData.message
          : "Error";
      }
    } catch (e) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedData(pagination)
    );
  }

  async onChange(item, checked) {
    try {
      const { authToken, history } = this.props;
      const { startIndex, limitNumber } = this.state;
      this.setState({
        isLoading: true,
      });
      const { loginUserData } = this.props;
      const city = loginUserData.role === 'Sub_admin' ? loginUserData.city : '';
      let statusData = await changeStatus(authToken, item.id, checked, city);

      if (statusData.status == 200) {
        notification["success"]({
          message: "Change Distributor Status",
          description: "Successfully changed status",
        });
        this.distributorTablePagination(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("Distributor", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleDetailsModalclose() {
    this.setState({
      editDistributedData: null,
      selectDistributorForCoin: null,
      transactionData: null,
    });
  }

  showModal(item) {
    this.setState(
      {
        editDistributedData: item,
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  addCoinInDistributorModal(item) {
    this.setState(
      {
        selectDistributorForCoin: item,
      },
      () => {
        this.modalAddCoinDistributor.show();
      }
    );
  }

  showUserDistributorTrnsModal(item) {
    this.setState(
      {
        transactionData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div key={"sno" + index}>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 150,
      key: "name",
      render: (text, item, index) => {
        return (
          <div key={"name" + index}>
            <div
              style={{
                alignItems: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div flexDirection="column" style={{ marginTop: "5px" }}>
                {item.first_name + " " + item.last_name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (text, item, index) => {
        return <div key={"email" + index}>{item.email ? item.email : "-"}</div>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (text, item, index) => {
        return <div>{item.phone ? item.phone : "-"}</div>;
      },
    },
    {
      title: "Percentage",
      key: "commission",
      render: (text, item, index) => {
        return <div>{item.commission ? item.commission : "-"}</div>;
      },
    },
    {
      title: "Balance (LYD)",
      key: "wallet balance",
      render: (text, item, index) => {
        console.log("item...", item);
        return (
          <div>
            <span style={{ color: "blue" }}>
              {" "}
              {item.total_balance.toFixed(2)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Active Status",
      key: "isActive",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_active != null ? (
              <Switch
                checked={item.is_active}
                style={{
                  backgroundColor: item.is_active
                    ? this.props.primaryColor
                    : "#bababa",
                }}
                onChange={this.onChange.bind(this, item)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      render: (text, item, index) => {
        return (
          <div style={{ display: "inline-block", width: "100%" }}>
            {
              <div mr="5px" style={{ float: "left" }}>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            }
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.delete ? (
              <div mr="5px" style={{ float: "left", marginLeft: "10px" }}>
                <Tooltip placement="bottom" title="Add Balance">
                  <Button
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      this.addCoinInDistributorModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            <div mr="5px" style={{ float: "left", marginLeft: "10px" }}>
              <Tooltip placement="bottom" title="Show Transaction">
                <Button
                  shape="circle"
                  icon={<TransactionOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showUserDistributorTrnsModal(item);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  editSubmit = async (body) => {
    const { startIndex, limitNumber } = this.state;
    try {
      this.setState({
        isLoading: true,
      });
      let createObject = body;
      let distributorData = await editDistributor(
        this.props.authToken,
        createObject
      );
      if (distributorData.status == 200) {
        this.setState({
          isLoading: true,
        });
        notification["success"]({
          message: "Distributor",
          description: "Successfully Edited",
        });

        this.distributorTablePagination(startIndex, limitNumber);

        return 1;
      } else {
        throw distributorData.message ? distributorData.message : "Error";
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      alertError("Distributor", error);
      return -1;
    }
  };

  render() {
    const { isLoading, distributorData, totalDistributorData } = this.state;
    const { authToken } = this.props;

    return isLoading ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <TableWrap
          data={distributorData}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={totalDistributorData}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
        />

        {this.state.editDistributedData ? (
          <EditModal
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            editDistributedData={this.state.editDistributedData}
            authToken={authToken}
            loginUserData={this.props.loginUserData}
            editSubmit={this.editSubmit}
          />
        ) : null}
        {this.state.selectDistributorForCoin ? (
          <AddCoinInDistributor
            admin={false}
            authToken={authToken}
            refx={(e) => (this.modalAddCoinDistributor = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            SelectedData={this.state.selectDistributorForCoin}
            // onSubmit={this.editSubmit}
          />
        ) : null}

        {this.state.transactionData ? (
          <DistributorTransaction
            admin={false}
            refx={(e) => (this.transactionRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            transactionListData={this.state.transactionData}
            authToken={authToken}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDistributorData: (distributorData) => {
      dispatch({
        type: "SET_DISTRIBUTOR_DATA",
        distributorData: distributorData,
      });
    },
    setTotalDistributorData: (totalDistributor) => {
      dispatch({
        type: "SET_DISTRIBUTOR_DATA_TOTAL",
        totalDistributor: totalDistributor,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DistributorTable));
