/* eslint-disable */

import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Default from "../components/dashboard/defaultCompo/default";
import Login from "../components/Login/Login";
import App from "../components/app";
import { connect } from "react-redux";
import { AuthRouteGaurd } from "./AuthRouteGuard";
import { UnAuthRouteGaurd } from "./UnAuthRouteGuard";
import User from "../components/User";
import Master from "../components/Master";
import Card from "../components/Card";
import Setting from "../components/Setting";
import Transaction from "../components/Transaction";
import Employee from "../components/Employee";
import Company from "../components/Company";
import CompanyTrn from "../components/CompanyTrn";
import Balance from "../components/Balance";
import Question from "../components/Question";
import Report from "../components/Report";
import BalanceRequest from "../components/balance-request";
import Distributor from "../components/distributor";
import Sales from "../components/sales";
import VerifyOTP from "../components/verify-otp";
import TrackUser from "../components/track-user/TrackUser";
import FreezeUser from "../components/FreezeUser";
import TicketSupport from "../components/ticket-support";
import Expenses from "../components/expenses";
import Inventory from "../components/inventory";
import SubAdmin from "../components/SubAdmin";

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loginFlag } = this.props;
    console.log("!!!!!!!!!!!!!!!loginFlag in router printed here", loginFlag);

    return (
      <Switch>
        <UnAuthRouteGaurd
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        />
        <UnAuthRouteGaurd
          exact
          path={`${process.env.PUBLIC_URL}/otp`}
          component={VerifyOTP}
        />

        <Fragment>
          <App>
            {/* dashboard menu */}
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Default}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/dashboard`}
              component={Default}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/balances`}
              component={Balance}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/user`}
              component={User}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/master`}
              component={Master}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/card`}
              component={Card}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/setting`}
              component={Setting}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/transaction`}
              component={Transaction}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/employee`}
              component={Employee}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/sub-admin`}
              component={SubAdmin}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/company`}
              component={Company}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/company-trn`}
              component={CompanyTrn}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/company-question`}
              component={Question}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/ticket-support`}
              component={TicketSupport}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/expenses`}
              component={Expenses}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/inventory`}
              component={Inventory}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/reports`}
              component={Report}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/balance-request`}
              component={BalanceRequest}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/distributor`}
              component={Distributor}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/sale`}
              component={Sales}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/activity-logs`}
              component={TrackUser}
            />
            <AuthRouteGaurd
              exact
              path={`${process.env.PUBLIC_URL}/freeze-user`}
              component={FreezeUser}
            />{" "}
          </App>
        </Fragment>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  loginFlag: state.auth.loginFlag,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);



