import React, { Fragment, Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Users,
  List,
  Gift,
  Settings,
  CreditCard,
  Flag,
  DollarSign,
  Table,
  Home,
} from "react-feather";
import { callAllKpis } from "../../../Graphs/Dashboard/callKpis";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { notification } from "antd";
import { Link } from "react-router-dom";
import SearchTransactionRecordsTable from "./SearchTransactionRecordsTable";
import { alertError } from "../../../utils/alert";

// TODO Unused Defalt , can be delete in future
class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCount: 0,
      categoryCount: 0,
      countryCount: 0,
      typeCount: 0,
      cardCount: 0,
      totalAdminBalance: 0,
      todaySaleAmount: 0,
      totalSaleCount: 0,
      todayCardSaleCount: 0,
      totalCardSaleCount: 0,
      isLoading: false,
      coinCirculationData: [],
    };
  }

  async componentDidMount() {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setRouteName,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      await setRouteName("DASHBOARD");
      const { loginUserData } = this.props;
      const city = loginUserData.role === "Sub_admin" ? loginUserData.city : "";
      let responseKpiData = await callAllKpis(authToken, city);

      if (responseKpiData.status == 200) {
        //set kpi data
        this.setState({
          userCount: responseKpiData.data.userCount,
          categoryCount: responseKpiData.data.categoryCount,
          countryCount: responseKpiData.data.countryCount,
          typeCount: responseKpiData.data.typeCount,
          cardCount: responseKpiData.data.cardCount,
          totalAdminBalance: responseKpiData.data.totalAdminBalance,
          todaySaleAmount: responseKpiData.data.todaySaleAmount,
          totalSaleCount: responseKpiData.data.totalSaleCount,
          todayCardSaleCount: responseKpiData.data.todayCardSaleCount,
          totalCardSaleCount: responseKpiData.data.totalCardSaleCount,
          coinCirculationData: responseKpiData.data.coinCirculationData,
          adminCoinData: responseKpiData.data.adminCoinData,
        });
      } else if (responseKpiData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw responseKpiData && responseKpiData.message
          ? responseKpiData.message
          : "Error";
      }
    } catch (e) {
      alertError("DASHBOARD", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const {
      userCount,
      countryCount,
      categoryCount,
      typeCount,
      cardCount,
      coinCirculationData,
    } = this.state;
    const {
      totalAdminBalance,
      todaySaleAmount,
      totalSaleCount,
      todayCardSaleCount,
      totalCardSaleCount,
      adminCoinData,
    } = this.state;
    const { loginUserData } = this.props;
    const accessPermission =
      (loginUserData.access_permission &&
        JSON.parse(loginUserData.access_permission)) ||
      false;
    console.log("!!!!!!!adminCoinData admin dashboard", adminCoinData);

    return loginUserData.role !== "Admin" &&
      (!accessPermission ||
        !accessPermission["dashboard"] ||
        accessPermission["dashboard"].view == false) ? (
      <Fragment>
        <h5 style={{ marginTop: "30%", marginLeft: "30%" }}>
          You are not authorized for view this page.
        </h5>
      </Fragment>
    ) : (
      <Fragment>
        <Breadcrumb
          parent="Dashboard"
          title="Dashboard"
          icon={<Home />}
          url="/dashboard"
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <Link to="/user">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Users />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Users</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={userCount} />
                        </h4>
                        <Users className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {this.props.loginUserData.role != "Sub_admin" && (
              <div className="col-sm-6 col-xl-3 col-lg-6">
                <Link to="/master?activeTab=1">
                  <div className="card o-hidden">
                    <div className="bg-primary b-r-4 card-body">
                      <div className="media static-top-widget">
                        <div className="align-self-center text-center">
                          <Flag />
                        </div>
                        <div className="media-body">
                          <span className="m-0">Total Countries</span>
                          <h4 className="mb-0 counter">
                            <CountUp className="counter" end={countryCount} />
                          </h4>
                          <Flag className="icon-bg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {this.props.loginUserData.role != "Sub_admin" && (
              <div className="col-sm-6 col-xl-3 col-lg-6">
                <Link to="/master?activeTab=2">
                  <div className="card o-hidden">
                    <div className="bg-primary b-r-4 card-body">
                      <div className="media static-top-widget">
                        <div className="align-self-center text-center">
                          <List />
                        </div>
                        <div className="media-body">
                          <span className="m-0">Total Categories</span>
                          <h4 className="mb-0 counter">
                            <CountUp className="counter" end={categoryCount} />
                          </h4>
                          <List className="icon-bg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {this.props.loginUserData.role != "Sub_admin" && (
              <div className="col-sm-6 col-xl-3 col-lg-6">
                <Link to="/card">
                  <div className="card o-hidden">
                    <div className="bg-primary b-r-4 card-body">
                      <div className="media static-top-widget">
                        <div className="align-self-center text-center">
                          <CreditCard />
                        </div>
                        <div className="media-body">
                          <span className="m-0">Total Cards</span>
                          <h4 className="mb-0 counter">
                            <CountUp className="counter" end={cardCount} />
                          </h4>
                          <CreditCard className="icon-bg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <Link to="/card">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <CreditCard />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Sold Cards</span>
                        <h4 className="mb-0 counter">
                          <CountUp
                            className="counter"
                            end={totalCardSaleCount}
                          />
                        </h4>
                        <CreditCard className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <Link to="/card">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <DollarSign />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Sold Amount (LYD)</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={totalSaleCount} />
                        </h4>
                        <DollarSign className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <Link to="/card">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <CreditCard />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Sold Cards Today</span>
                        <h4 className="mb-0 counter">
                          <CountUp
                            className="counter"
                            end={todayCardSaleCount}
                          />
                        </h4>
                        <CreditCard className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-sm-6 col-xl-3 col-lg-6">
              <Link to="/card">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <DollarSign />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Sold Amount Today (LYD)</span>
                        <h4 className="mb-0 counter">
                          <CountUp className="counter" end={todaySaleAmount} />
                        </h4>
                        <DollarSign className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-sm-6 col-xl-3 col-lg-6">
              <Link to="/card">
                <div className="card o-hidden">
                  <div className="bg-primary b-r-4 card-body">
                    <div className="media static-top-widget">
                      <div className="align-self-center text-center">
                        <Gift />
                      </div>
                      <div className="media-body">
                        <span className="m-0">Total Sold Cards</span>
                        <h4 className="mb-0 counter">
                          <CountUp
                            className="counter"
                            end={totalCardSaleCount}
                          />
                        </h4>
                        <Gift className="icon-bg" />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-sm-6 col-xl-3 col-lg-6">
                                    <div className="card o-hidden">
                                        <div className="bg-primary b-r-4 card-body">
                                            <div className="media static-top-widget">
                                            <div className="align-self-center text-center">
                                                <Gift />
                                            </div>
                                            <div className="media-body"><span className="m-0">Total Admin balance</span>
                                                <h4 className="mb-0 counter">
                                                <CountUp className="counter" end={totalAdminBalance} />
                                                </h4>
                                                <Gift className="icon-bg" />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
            {coinCirculationData &&
              coinCirculationData.length > 0 &&
              coinCirculationData.map((data, index) => {
                return (
                  <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card o-hidden">
                      <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <DollarSign />
                          </div>
                          <div className="media-body">
                            <span className="m-0">{`${data.coin.name} Circulation`}</span>
                            <h4 className="mb-0 counter">
                              <CountUp
                                className="counter"
                                end={data.total_amount}
                              />
                            </h4>
                            <DollarSign className="icon-bg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {adminCoinData &&
              adminCoinData.length > 0 &&
              adminCoinData.map((data, index) => {
                return (
                  <div className="col-sm-6 col-xl-3 col-lg-6">
                    <div className="card o-hidden">
                      <div className="bg-primary b-r-4 card-body">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <DollarSign />
                          </div>
                          <div className="media-body">
                            <span className="m-0">{`${data.coin.name} Admin Balance`}</span>
                            <h4 className="mb-0 counter">
                              <CountUp
                                className="counter"
                                end={data.total_wallet_balance}
                              />
                            </h4>
                            <DollarSign className="icon-bg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div>
          <div
            style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}
          >
            Search Company Transactions
          </div>
          <div style={{ marginTop: "10px" }}>
            <SearchTransactionRecordsTable />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setRouteName: (routeName) => {
      dispatch({
        type: "SET_ROUTE_NAME",
        routeName: routeName,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Default));
