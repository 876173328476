import { callGetApiToServer } from '../callApi';

export async function getAllThirdPartyCard(
    authtoken,
    id
) {
    try {
        let header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "authorization": "Berear " + authtoken
        }

        let endUrl = "v1/auth/fetch-api-cards/" + id
        console.log("end url", endUrl);
        console.log("endUrl printed here", endUrl);

        let responseData = await callGetApiToServer(header, "GET", endUrl);

        return responseData;
    } catch (err) {
        throw err;
    }
}
